import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Divider, Image, Popconfirm, Breadcrumb, Tag, Tooltip } from "antd";
import { Actions, Store } from "../../reflux/index";
export default class TicketList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_header: Store.collapseHeader,
      results: [],
      stageId: props.match.params.stageId,
      pageIndex: 1,
      pageSize: 10
    };
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentDidMount() {
    Actions.call('stage.single', { '_id': this.state.stageId })
    Actions.call("ticket.list", { stageId: this.state.stageId, pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
  }

  componentWillUnmount() {
    this.un_index();
  }

  columns = [
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      width: 100,
      render: (text, it) => {
        switch (it.state) {
          case -2: return <Tag color="#f50">退款</Tag>;
          case -1: return <Tag color="#f50">作废</Tag>;
          case 0: return <Tag color="gold">未销售</Tag>;
          case 1: return <Tag color="#2db7f5">锁定</Tag>;
          case 2: return <Tag color="#2db7f5">已销售</Tag>;
          case 3: return <Tag color="#2db7f5">完成</Tag>;
        }
      }
    }, 
    {
      title: "门票编号",
      dataIndex: "_id",
      key: "_id",  
    },{
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile", 
    },{
      title: "渠道",
      dataIndex: "channelId",
      key: "channelId", 
    }, 
    {
      title: "操作",
      key: "op",
      width: 160,
      render: (text, record) => {
        switch (record.state) {
          case -1:
            return null;
          default:
            return <Space>
              <Popconfirm
                title="确定要下架此产品吗?"
                onConfirm={() => {
                  Actions.call("ticket.delete", [record._id]);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">作废</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Link to={`/ticket/create/${record.stageId}/${record._id}`}>修改</Link>             
            </Space>
        }
      }
    }
  ];

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>门票管理</Breadcrumb.Item> 
            <Breadcrumb.Item>
              <Link to={`/goods/list/${this.state.entId}`}>产品列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>产品（{this.state.goodsName}）</Breadcrumb.Item> 
          </Breadcrumb>
          <h1>门票列表</h1>
          产品（{this.state.goodsName}）({this.state.begin} ~ {this.state.end})期。
        </div>
      );
    }
  };

  onStatusChange = (type, data, page) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch (page) {
          case "ticket.list":
            this.setState({ ...data.data });
            break;
            case "ticket.delete":
              Actions.call("ticket.list", { stageId: this.state.stageId, pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
              break;
          case "stage.single":
            this.setState({ goodsName: data.data.name, begin:data.data.begin, end:data.data.end, entId: data.data.entId });
            break;
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            {/* <Link to={`/stage/create/${this.state.goodsId}/new`}>
              <Button className="margin-bootom-normal">添加期</Button>
            </Link> */}
          </div>
          <Table
            rowKey="_id"
            bordered
            columns={this.columns}
            dataSource={this.state.list}
          />
        </div>
      </div>
    );
  }
}
