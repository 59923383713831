import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PortalActions, PortalStore } from "../../../reflux/portal";
import { Actions, Store } from "../../../reflux/index";
import BrowserHistory from "../../../history/browserhistory"; 
import { Input, Form, Breadcrumb,  Button, Space } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons"; 
export default class PortalUserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portalId: props.match.params.portalId,
      id: props.match.params.id,
      page_header: Store.collapseHeader
    };
    this.un_store = PortalStore.listen(this.onStatusChange);
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentWillUnmount() {
    this.un_store();
    this.un_index();
  }

  componentDidMount() {
    if (this.state.id !== "new") {
      PortalActions.portal({ id: this.state.id });
    }
  }

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>小店管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/portal/list">小店列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/user/list/${this.state.portalId}`}>用户列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.state.id === "new" ? "新增" : ""}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>用户信息</h1>
          <span>
            {this.state.id === "new" ? "新增" : "修改"}
            用户信息
          </span>
        </div>
      );
    }
  };

  onStatusChange = (type, data, module) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "portal":
        this.refs.form.setFieldsValue(data);
        break;
      case "update":
      case "portalUserAdd":
        Actions.globalMessage("success", "数据保存完成！");
        setTimeout(() => {
          BrowserHistory.push(`/user/list/${this.state.portalId}`);
        }, 500);
        break;
      default:
        break;
    }
  };

  onFinish = values => {
    if (this.state.id === "new") {
      values.identity = "+86:" + values.identity;
      values.portalID = this.state.portalId;
      PortalActions.portalUserAdd(values);
    } else {
      values.id = this.state.id;
      PortalActions.portalUserAdd(values);
    }
  };

  // 相关附件table头
  columns = [
    {
      title: "名称",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "操作",
      key: "op",
      width: 200,
      dataIndex: "op",
      render: (text, record) => {
        return <Space></Space>;
      }
    }
  ];

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div className="p16">
          <div className="ant-card ant-card-bordered margin-bootom-normal">
            {/*  ---------基础信息--------- */}
            <div className="ant-card-head">
              <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title">基础信息</div>
              </div>
            </div>
            <div className="mt-20px">
              <Form
                ref="form"
                initialValues={{ visible: true }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="identity"
                  label={<span>手机号</span>}
                  rules={[{ required: true, message: "请输入手机号!" }]}
                >
                  <Input className="w-400px" placeholder="请输入手机号" />
                </Form.Item>
              </Form>
            </div>
            {/*  ---------保存--------- */}
            <div className="p24 border-top-1px">
              <Space>
                <Link to={`/user/list/${this.state.portalId}`}>
                  <Button icon={<ArrowLeftOutlined />}>取消</Button>
                </Link>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.refs.form.submit();
                  }}
                >
                  保存
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
