import getConfig from "../config/index";
const Config = getConfig();
class Common {
  isMobile = () => {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      console.log("手机访问.");
      return true;
    } else {
      console.log("电脑访问.");
      return false;
    }
  };

  guid = () => {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (
      S4() +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      "-" +
      S4() +
      S4() +
      S4()
    );
  };
  checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 500) {
      return response;
    } else {
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  };
  parseJSON = response => {
    return response.json();
  };
  parseText = response => {
    return response.text();
  };
  get = (url, cb) => {
    fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      }
    })
      .then(this.checkStatus)
      .then(this.parseJSON)
      .then(json => {
        cb(json, null, 200);
      })
      .catch(error => {
        if (error.response) {
          cb(error.response, error.message, error.response.status);
        } else {
          cb(null, error.message, 500);
        }
      });
  };
  post = (url, params, cb) => {
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/javascript;charset=UTF-8"
      },
      xhrFields: {
        withCredentials: true
      },
      credentials: "include",
      body: JSON.stringify(params)
    })
      .then(this.checkStatus)
      .then(this.parseJSON)
      .then(json => {
        cb(json, null, 200);
      })
      .catch(error => {
        if (error.response) {
          cb(error.response, error.message, error.response.status);
        } else {
          cb(null, error.message, 500);
        }
      });
  };
  graphqlUrl = (url, cmd, cb) => {
    fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      credentials: "include",
      xhrFields: {
        withCredentials: true
      },
      body: JSON.stringify(cmd)
    })
      .then(this.checkStatus)
      .then(this.parseJSON)
      .then(json => {
        if (json.errors) {
          if (json.errors[0].status) {
            cb(json, json.errors[0].message, json.errors[0].status);
          } else {
            cb(json, json.errors[0].message, 500);
          }
        } else {
          cb(json, null, 200);
        }
      })
      .catch(error => {
        if (error.response) {
          cb(null, error.message, error.response.status);
        } else {
          cb(null, error.message, 500);
        }
      });
  };
  graphql = (cmd, cb) => {
    this.graphqlUrl(Config.host + "/api", cmd, cb);
  };
  upToken = async (fileName, dir) => {
    return new Promise((resolve, reject) => {
       
      fetch(Config.host + "/api/get_oss_token", {
        method: "post",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        credentials: "include",
        body: `{"dir":"${dir}"}`
      })
        .then(this.checkStatus)
        .then(this.parseJSON)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  getCity = async address => {
    // 这是 server key，跨域
    return new Promise((resolve, reject) => {
      let url = `https://restapi.amap.com/v3/geocode/geo?address=${address}&output=json&key=11ef02e45ea082f955f20e5d455cb722`;
      fetch(url, {
        method: "get",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        credentials: "include"
      })
        .then(this.checkStatus)
        .then(this.parseJSON)
        .then(json => {
          resolve(json);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
  citys = [
    "北京",
    "上海",
    "广州",
    "深圳",
    "东莞",
    "西安",
    "无锡",
    "南昌",
    "万宁",
    "三亚",
    "三明",
    "上饶",
    "东方",
    "东营",
    "中山",
    "临夏回族自治州",
    "临沂",
    "丹东",
    "丽江",
    "乌鲁木齐",
    "乐东黎族自治县",
    "乐山",
    "九江",
    "云浮",
    "亳州",
    "仙桃",
    "伊犁哈萨克自治州",
    "佛山",
    "保定",
    "信阳",
    "儋州",
    "六安",
    "六盘水",
    "兰州",
    "内江",
    "北海",
    "十堰",
    "南京",
    "南充",
    "南宁",
    "南平",
    "南通",
    "厦门",
    "台州",
    "合肥",
    "吉安",
    "周口",
    "咸宁",
    "哈尔滨",
    "唐山",
    "商丘",
    "嘉兴",
    "嘉峪关",
    "大理白族自治州",
    "大连",
    "天水",
    "天津",
    "天门",
    "太原",
    "娄底",
    "孝感",
    "宁德",
    "宁波",
    "安庆",
    "安阳",
    "安顺",
    "定西",
    "宜宾",
    "宜昌",
    "宜春",
    "宣城",
    "宿州",
    "宿迁",
    "屯昌县",
    "山南",
    "岳阳",
    "崇左",
    "常州",
    "常德",
    "平凉",
    "庆阳",
    "廊坊",
    "延边朝鲜族自治州",
    "开封",
    "张家口",
    "张家界",
    "张掖",
    "徐州",
    "德阳",
    "忻州",
    "怀化",
    "恩施土家族苗族自治州",
    "惠州",
    "成都",
    "扬州",
    "抚州",
    "拉萨",
    "揭阳",
    "文山壮族苗族自治州",
    "文昌",
    "新乡",
    "新余",
    "日照",
    "昆明",
    "昭通",
    "晋中",
    "景德镇",
    "曲靖",
    "来宾",
    "杭州",
    "柳州",
    "株洲",
    "桂林",
    "梅州",
    "梧州",
    "武威",
    "武汉",
    "毕节",
    "永州",
    "汕头",
    "汕尾",
    "江门",
    "池州",
    "沈阳",
    "沧州",
    "河池",
    "河源",
    "泉州",
    "泰安",
    "泰州",
    "泸州",
    "洛阳",
    "济南",
    "济宁",
    "济源",
    "海口",
    "海西蒙古族藏族自治州",
    "淄博",
    "淮南",
    "淮安",
    "清远",
    "温州",
    "湖州",
    "湘潭",
    "湘西土家族苗族自治州",
    "湛江",
    "滨州",
    "漯河",
    "漳州",
    "潍坊",
    "潜江",
    "烟台",
    "焦作",
    "玉林",
    "玉溪",
    "珠海",
    "琼海",
    "甘南藏族自治州",
    "白银",
    "百色",
    "益阳",
    "盐城",
    "眉山",
    "石家庄",
    "福州",
    "秦皇岛",
    "红河哈尼族彝族自治州",
    "绍兴",
    "绵阳",
    "肇庆",
    "自贡",
    "艺龙测试",
    "芜湖",
    "苏州",
    "茂名",
    "荆州",
    "荆门",
    "菏泽",
    "蚌埠",
    "衡阳",
    "衢州",
    "襄阳",
    "西宁",
    "许昌",
    "贵港",
    "贵阳",
    "贺州",
    "赣州",
    "迪庆藏族自治州",
    "通辽",
    "遵义",
    "邢台",
    "邵阳",
    "郑州",
    "郴州",
    "鄂州",
    "酒泉",
    "重庆",
    "金华",
    "金边",
    "钦州",
    "铜仁",
    "锦州",
    "镇江",
    "长春",
    "长沙",
    "长治",
    "阜阳",
    "防城港",
    "阳江",
    "陇南",
    "陵水黎族自治县",
    "随州",
    "青岛",
    "韶关",
    "马鞍山",
    "驻马店",
    "黄冈",
    "黄山",
    "黄石",
    "黔东南苗族侗族自治州",
    "黔西南布依族苗族自治州"
  ];
  searchCity = key => {
    let cityList = [];
    for (let c of this.citys) {
      if (c.indexOf(key) > -1) {
        cityList.push({ label: c, value: c });
      }
    }
    return cityList;
  };
  getUrlParam = (search, key) => {
    let value = null;
    if (search.length > 0) {
      search = search.substr(1);
      let arr = search.split("&");
      for (let it of arr) {
        let kv = it.split("=");
        if (kv[0] === key) {
          value = kv[1];
        }
      }
    }
    return value;
  };
  indexOf = (arr, value, fn) => {
    for (let i = 0; i < arr.length; i++) {
      if (fn(arr[i], value)) {
        return i;
      }
    }
    return -1;
  };
  remove = (arr, value, fn) => {
    let index = this.indexOf(arr, value, fn);
    if (index > -1) {
      arr.splice(index, 1);
    }
  };
  getCurrentUserType = ()=>{
    let entityString = sessionStorage.getItem('current_user_type');
    let res = null;
    if(entityString !== null){
      res = JSON.parse(entityString)
    } 
    return res 
  }
  clearCurrentUserType = ()=>{
    sessionStorage.setItem('current_user_type',null)
  }
  setCurrentUserType = (type, data)=>{
    let entity = {type, data}
    sessionStorage.setItem('current_user_type',JSON.stringify(entity))
  }
}

export default new Common();
