import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Actions, Store } from "../../reflux/index";
import BrowserHistory from "../../history/browserhistory";
import XyEditor from "../../components/xyEditor";
import Logo from "../../components/logo";
import BraftEditor from 'braft-editor';     // 引入编辑器组件
import moment from "moment";
import { Input, DatePicker, Form, Switch, Breadcrumb, Button, InputNumber, Alert, Space, Radio, Upload } from "antd";
import { SaveOutlined, ArrowLeftOutlined, SettingOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
export default class StageCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goodsId: props.match.params.goodsId,
            id: props.match.params.id,
            page_header: Store.collapseHeader,
            modify: false,
            // 创建一个空的editorState作为初始值
            editorState: BraftEditor.createEditorState(null)
        };
        this.un_index = Store.listen(this.onStatusChange);
    }
    componentWillUnmount() {
        this.un_index();
    }
    componentDidMount() { 
        if (this.state.id !== "new") {
            this.setState({modify:true});
            Actions.call("stage.single", { _id: this.state.id });
        }else{
            Actions.call("goods.single", { _id: this.state.goodsId });
        }
    }
    getPageHeader = () => {
        if (this.state.page_header === "0") {
            return (
                <div className="info-header">
                    <Breadcrumb>
                        <Breadcrumb.Item>产品管理</Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/goods/list/${this.state.entId}`}>产品列表</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/stage/list/${this.state.goodsId}`}>期列表</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {this.state.id === "new" ? "新增" : ""}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h1>期信息</h1>
                    <span>
                        {this.state.id === "new" ? "新增" : "修改"}
                        产品。
                    </span>
                </div>
            );
        }
    };
    onStatusChange = (type, data, module) => {
        switch (type) {
            case "collapseHeader":
                this.setState({ page_header: data });
                break;
            case "call":
                switch (module) {
                    case "stage.create":
                    case "stage.create_by_goodsId":
                    case "stage.update":
                        if (data.code === 200) {
                            Actions.globalMessage("success", "数据保存完成！");
                            setTimeout(() => {
                                BrowserHistory.push(`/stage/list/${this.state.goodsId}`);
                            }, 500);
                        } else {
                            Actions.globalMessage("error", data.data.message);
                        }
                        break;
                    case "stage.single":
                        let interval = [moment(data.data.begin), moment(data.data.end)];  
                        this.setState({begin:data.data.begin, end:data.data.end});
                        this.refs.formBase.setFieldsValue({interval});
                    case "goods.single":                    
                        let temp = data.data; 
                        temp.level_0 = temp.channel_level.level_0;
                        temp.level_1 = temp.channel_level.level_1;
                        temp.level_2 = temp.channel_level.level_2;
                        temp.level_3 = temp.channel_level.level_3;
                        this.refs.form.setFieldsValue(temp);
                        this.setState({ raw: temp.raw, entId:temp.entId, third_sales:temp.third_sales });
                        break;
                    default: break;
                }break;

            default:
                break;
        }
    };

     
    onFinish = values => { 
        values.channel_level = {
            level_0: values.level_0,
            level_1: values.level_1,
            level_2: values.level_2,
            level_3: values.level_3,
          };
        delete values.level_0;
        delete values.level_1;
        delete values.level_2;
        delete values.level_3; 
   
        // 三方佣金
        if (values.third_commission === undefined) {
            values.third_commission = 0;
        }

        values.content = this.state.editorState.toHTML()
        values.raw = this.state.editorState.toRAW()
        values.goodsId = this.state.goodsId; 
        values.begin = this.state.begin;
        values.end = this.state.end;

        if (this.state.id === "new") {
            Actions.call("stage.create", values);
        } else {
            values._id = this.state.id;
            Actions.call("stage.update", values);
        }
    };
    render() {
        return (
            <div>
                {this.getPageHeader()}
                <div className="p16">
                    <div className="ant-card ant-card-bordered margin-bootom-normal">
                        {/*  ---------基础信息--------- */}
                        <div className="ant-card-head">
                            <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">基础信息</div>
                            </div>
                        </div>
                        <div className="mt-20px">
                            <Form
                                ref="formBase"
                                onFinish={(values)=>{ 
                                    Actions.call('stage.create_by_goodsId', 
                                    {
                                        "goodsId":this.state.goodsId, 
                                        "begin":this.state.begin,
                                        "end":this.state.end,
                                    })
                                }}
                            >
                                <Form.Item
                                    name='interval'
                                    label={<span>本期时间</span>}
                                    rules={[{ required: true, message: "请输入开始结束日期!" }]}
                                >
                                    <RangePicker
                                        onChange={(date, dateString) => {
                                            this.setState({
                                                begin:dateString[0],
                                                end:dateString[1]
                                            });
                                        }} />
                                </Form.Item>
                            </Form>
                        </div>
                        {/*  ---------保存--------- */}
                        {this.state.modify ? null : <div className="p24 border-top-1px">
                            <div className="ant-form-item">
                                <Alert message='（保存）将使用已有产品基本信息，创建本期产品。如本期产品需要调整细节，请选择（定制细节）。' type='info' showIcon
                                    style={{ border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                            </div>
                            <Space>
                                <Link to={`/goods/list/${this.state.entId}`}>
                                    <Button icon={<ArrowLeftOutlined />}>取消</Button>
                                </Link>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={() => {
                                        this.refs.formBase.submit();
                                    }}
                                >
                                    保存
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    icon={<SettingOutlined />}
                                    onClick={() => {
                                        this.setState({modify:true});
                                        Actions.call("goods.single", { _id: this.state.goodsId });

                                    }}
                                >
                                    定制细节
                                </Button>
                            </Space>
                        </div>}
                        
                    </div>
                    {this.state.modify ? <div className="ant-card ant-card-bordered margin-bootom-normal">
                        {/*  ---------产品详情--------- */}
                        <div className="ant-card-head">
                            <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">产品详情</div>
                            </div>
                        </div>
                        <div className="mt-20px">
                            <Form
                                ref="form"
                                initialValues={{
                                    third_commission: 0,
                                    full_count: 1,
                                    base_count: 1,
                                    traffic: '接站',
                                    meal: '有',
                                    price: 0, third_sales: false, level_0: 0, level_1: 0, level_2: 0, level_3: 0
                                }}
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    name="name"
                                    label={<span>产品名称</span>}
                                    rules={[{ required: true, message: "请输入产品名称!" }]}
                                >
                                    <Input className="w-400px" placeholder="请输入产品名称" />
                                </Form.Item>
                                <Form.Item
                                    name="price"
                                    label={<span>价格(元)</span>}
                                    rules={[{ required: true, message: "请输入产品价格!" }]}
                                >
                                    <InputNumber className="w-400px" min={0} />
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Space>
                                        <Form.Item label={<span>满团人数</span>} name="full_count" rules={[
                                            { required: true, message: "请输入满团人数!" },
                                        ]}>
                                            <InputNumber className="w-400px" min={1} />
                                        </Form.Item>
                                        <div className="ant-form-item">
                                            <Alert message='一个团最多多少人' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </div>
                                    </Space>
                                </Form.Item>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Space>
                                        <Form.Item label={<span>成团人数</span>} name="base_count" rules={[
                                            { required: true, message: "请输入满团人数!" },
                                        ]}>
                                            <InputNumber className="w-400px" min={1} />
                                        </Form.Item>
                                        <div className="ant-form-item">
                                            <Alert message='最少多人可以成团' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </div>
                                    </Space>
                                </Form.Item>
                                <Form.Item noStyle >
                                    <Space>
                                        <Form.Item
                                            name="third_sales"
                                            valuePropName="checked"
                                            label={<span>三方销售</span>}
                                            rules={[{ required: false, message: "请输入产品价格!" }]}
                                        >
                                            <Switch onChange={(e) => { this.setState({ third_sales: e }) }} />
                                        </Form.Item>
                                        <div style={{ width: 348 }}>
                                            {this.state.third_sales ? <Form.Item
                                                name="third_commission"
                                                label={<span>分成（元）</span>}

                                            >
                                                <InputNumber style={{ width: 188 }} min={0} />
                                            </Form.Item> : null}
                                        </div>
                                        <div className="ant-form-item">
                                            <Alert message='开启后，此产品任意渠道可见，可销售' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </div>
                                    </Space>
                                </Form.Item>
                                <Form.Item
                                    extra="为不同等级的销售渠道，配置销售佣金（元）。例：内部员工可以配置为0级，分成0元，外部渠道根据能力高低配置更高的佣金。"
                                    label={<span>渠道佣金</span>}
                                    rules={[{ required: false }]}
                                >
                                    <div className="margin-bootom-small">
                                        <Space>
                                            <Form.Item noStyle name='level_0'><InputNumber min={0} className="w-400px" /></Form.Item>

                                            <Alert message='等级0' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </Space>
                                    </div>
                                    <div className="margin-bootom-small">
                                        <Space>
                                            <Form.Item noStyle name='level_1'><InputNumber min={0} className="w-400px" /></Form.Item>

                                            <Alert message='等级1' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </Space>
                                    </div>
                                    <div className="margin-bootom-small">
                                        <Space>
                                            <Form.Item noStyle name='level_2'><InputNumber min={0} className="w-400px" /></Form.Item>

                                            <Alert message='等级2' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </Space>
                                    </div>
                                    <div className="margin-bootom-small">
                                        <Space>
                                            <Form.Item noStyle name='level_3'><InputNumber min={0} className="w-400px" /></Form.Item>

                                            <Alert message='等级3' type='info' showIcon
                                                style={{ marginLeft: 8, border: 0, padding: 0, background: '#fff', color: '#ccc !important' }} />
                                        </Space>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="traffic"
                                    label={<span>交通情况</span>}
                                    rules={[{ required: false, message: "请选择交通情况!" }]}
                                >
                                    <Radio.Group  >
                                        <Radio value="接站">接站</Radio>
                                        <Radio value="自驾">自驾</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="meal"
                                    label={<span>餐饮</span>}
                                    rules={[{ required: false, message: "请选择餐饮情况!" }]}
                                >
                                    <Radio.Group  >
                                        <Radio value="有">有</Radio>
                                        <Radio value="无">无</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="头像（方图）">
                                    <Logo
                                        change={(osspath, url) => {
                                            let photos = []
                                            this.setState({ photos })

                                        }
                                        }
                                        dir={`goods/${this.state.entId}/`}
                                        photos={[]}
                                    />
                                </Form.Item>
                                <Form.Item label="头像（长条图）">
                                    <Logo
                                        change={(osspath, url) => {
                                            let photos = []
                                            this.setState({ photos })

                                        }
                                        }
                                        dir={`goods/${this.state.entId}/`}
                                        photos={[]}
                                    />
                                </Form.Item>
                                <Form.Item label="海报底图">
                                    <Logo
                                        change={(osspath, url) => {
                                            let photos = []
                                            this.setState({ photos })

                                        }
                                        }
                                        dir={`goods/${this.state.entId}/`}
                                        photos={[]}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                        {/* 产品详情 */}
                        <div className="ant-card-head border-top-1px">
                            <div className="ant-card-head-wrapper">
                                <div className="ant-card-head-title">产品详情</div>
                            </div>
                        </div>
                        <div className="flex space-around">
                            <div style={{ flexShrink: 0, width: 375, height: 650, padding: 16, background: '#eee', overflowY: 'auto', overflowX: 'hidden' }}
                                dangerouslySetInnerHTML={{ __html: this.state.editorState.toHTML() }}
                            ></div>
                            <div style={{ flexGrow: 1 }} >
                                <XyEditor
                                    dir={`goods/${this.state.entId}/`}
                                    raw={this.state.raw}
                                    onChange={(editorState) => { this.setState({ editorState }) }} />
                            </div>
                        </div>

                        {/*  ---------保存--------- */}
                        <div className="p24 border-top-1px">
                            <Space>
                                <Link to={`/goods/list/${this.state.entId}`}>
                                    <Button icon={<ArrowLeftOutlined />}>取消</Button>
                                </Link>
                                <Button
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={() => {
                                        this.refs.form.submit();
                                    }}
                                >
                                    保存
                                </Button>
                            </Space>
                        </div>

                    </div>:null}
                    
                </div>
            </div>
        );
    }
}