import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Divider, Image, Popconfirm, Breadcrumb ,Tag, Tooltip} from "antd";
import { Actions, Store } from "../../reflux/index";
export default class GoodsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_header: Store.collapseHeader,
      results: [],
      entId:props.match.params.entId,
      pageIndex: 1,
      pageSize: 10
    }; 
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentDidMount() {
    Actions.call("goods.list", { entId:this.state.entId, pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
  }

  componentWillUnmount() {
    this.un_index();
  }

  columns = [
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      width:100,
      render: (text, it) => {
        switch (it.state) {
          case -1: return <Tag color="#f50">注销</Tag>;
          case 0: return <Tag color="gold">待审核</Tag>;
          case 1: return <Tag color="#2db7f5">正常</Tag>;
        }
      }
    },
    {
      title: "产品名称",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "价格",
      dataIndex: "price",
      key: "price" 
    },
    {
      title: "满团人数",
      dataIndex: "full_count",
      key: "full_count" 
    },
    {
      title: "第三方销售",
      dataIndex: "full_count",
      key: "full_count",
      render:(text, it)=>{
        if(it.third_sales){
          return '开启'
        }
      }
    },{
      title: "期",
      key:'stage',
      width: 100,
      render:(text, record)=>{return <Link to={`/stage/list/${record._id}`}>期</Link>}
    },
    {
      title: "操作",
      key: "op",
      width: 160,
      render: (text, record) => {
        switch(record.state){
          case -1:
            return null;
          default:
            return <Space> 
            <Popconfirm
              title="确定要下架此产品吗?"
              onConfirm={()=>{
                Actions.call("goods.delete", [record._id ]);
              }} 
              okText="Yes"
              cancelText="No"
            >
              <a href="#">下架</a>
            </Popconfirm>
            <Divider type="vertical" /> 
            <Link to={`/goods/create/${this.state.entId}/${record._id}`}>修改</Link>
            
          </Space>
        } 
      }
    }
  ];

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>产品管理</Breadcrumb.Item>
          </Breadcrumb>
          <h1>产品列表</h1>
          企业可以创建多个产品，为产品指定级别，级别与产品销售的分成金额有关。一个产品可以分很多“期”，每期的启止日期不同。
        </div>
      );
    }
  };

  onStatusChange = (type, data, page) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch (page) {
          case "goods.list":
            this.setState({ ...data.data });
            break;
          case "goods.delete":
            Actions.call("goods.list", { entId:this.state.entId, pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
            break;
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            <Link to={`/goods/create/${this.state.entId}/new`}>
              <Button className="margin-bootom-normal">添加产品</Button>
            </Link>
          </div>
          <Table
            rowKey="_id"
            bordered
            columns={this.columns}
            dataSource={this.state.list}
          />
        </div>
      </div>
    );
  }
}
