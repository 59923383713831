import React from "react";
import getConfig from "../../config/index";
import Common from '../../common';
import { Layout, Menu, Avatar, Tooltip, Space, Dropdown, message } from "antd";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import {
  UsergroupAddOutlined,
  SettingOutlined,
  DownOutlined, HomeOutlined,
  UpOutlined,UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,DollarOutlined,
  BankOutlined,CreditCardOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import "../../index.css";
import "./index.css";

import { Actions, Store } from "../../reflux/index";
import { MessageStore } from "../../reflux/message";
import BrowserHistory from "../../history/browserhistory";

// user
import UserInfo from "../user/info";

// admin
import AdminList from "../admin/list";
import AdminCreate from "../admin/create";
// ent
import EntList from "../ent/list";
import EntCreate from "../ent/create";

// channel
import ChannelList from "../channel/list";
import ChannelCreate from "../channel/create";

// goods
import GoodsList from '../goods/list';
import GoodsCreate from "../goods/create";

// stage
import StageList from "../stage/list";
import StageCreate from "../stage/create";

// 卡券
import TicketList from '../ticket/list';

import PortalUserList from "../system/user/list";
import PortalUserCreate from "../system/user/create";
import PortalRoleList from "../system/role/list";
import PortalRoleCreate from "../system/role/create";


const { Header, Content, Sider } = Layout;
const Config = getConfig();
const { SubMenu } = Menu;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page_header: "0", user: {}, faas_menu: [] };
    this.unsubscribe_index = Store.listen(this.onStatusChange.bind(this));
    this.unsubscribe_msg = MessageStore.listen(this.onStatusChange.bind(this));
  }
  componentWillUnmount() {
    this.unsubscribe_index();
    this.unsubscribe_msg();
  }

  async componentDidMount() {
    await Actions.viewer();
  }

  /** 获取相对地址 */
  GetUrlRelativePath() {
    var url = document.location.toString();
    return url;
  }

  onStatusChange = (type, data, module, params) => {
    switch (type) {
      case "call":
        switch (module) {
          case "menu.list":
            this.setState({ faas_menu: data.data.list });
            break;
          default:
            break;
        }
        break;
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "global-message":
        message[data.type](data.message);
        break;
      case "signOut":
        BrowserHistory.push("/signin");
        break;
      case "viewer": // 获取当前用户
        console.log("app > index > viewer", data);
        if (data.code !== 200) {
          BrowserHistory.push("/signin"); // 去登录
        } else {
          this.setState({ ...data.data });
        }
        break;
      default:
        break;
    }
  };

  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  /* 头像上的悬浮菜单 */ 
  getTopMenu = ()=>{
    let currentUser = Common.getCurrentUserType(); 
    let _id = currentUser ? currentUser.data._id:null; 
    let entGroup = <Menu.ItemGroup title={<Space><HomeOutlined />企业</Space>} >
      {
        this.state.ent ? this.state.ent.map((it, index)=>{
          return <Menu.Item key={it._id}>
          <div style={{paddingLeft:14}} onClick={()=>{
            Common.setCurrentUserType('ent', it);
            this.setState({'current':it._id})
            BrowserHistory.push('/')
          }}>{it.name}</div>
        </Menu.Item>
        }):null
      } 
      </Menu.ItemGroup> 
    let channelGroup = <Menu.ItemGroup title={<Space><ShareAltOutlined />渠道</Space>} >
      {
        this.state.channel ? this.state.channel.map((it, index)=>{
          return <Menu.Item key={it._id}  >
          <div style={{paddingLeft:14}} onClick={()=>{
            Common.setCurrentUserType('channel', it);
            this.setState({'current':it._id})
            BrowserHistory.push('/')
          }}>{it.name}</div>
        </Menu.Item>
        }):null
      } 
    </Menu.ItemGroup> 
    let res = <Menu style={{ width: 200 }} 
    selectedKeys={[_id]}
    onClick={(data)=>{   
      switch(data.key){
        case "/user/signOut":Actions.signOut();break;
        case "/user/info":
        BrowserHistory.push(data.key);break;
      }
    }}>
      {entGroup}
      {this.state.ent ? <Menu.Divider />:null}
      {channelGroup}
      {this.state.channel ? <Menu.Divider />:null}
      <Menu.Item key="/user/info">
        <Space><SettingOutlined />个人设置</Space>
      </Menu.Item>
      <Menu.Item key="/user/signOut">
      <Space><LogoutOutlined />退出登录</Space> 
      </Menu.Item>
    </Menu>
    return res;
  }
 
  /**
   * 主菜单点击事件
   */
  menuClick = (e) => {
    this.setState({ selectedMenuKeys: [e.key] });
    BrowserHistory.push(e.key);
  };
  /* 左侧菜单 */
  getLeftMenu = ()=>{
    let current = Common.getCurrentUserType();
    let res = [];
    if(current !== null){
      switch(current.type){
        case "ent":
          res.push({label:'企业管理', icon: <HomeOutlined />, key: '/ent/list'})
          res.push({label:'员工管理', icon: <UsergroupAddOutlined />, key: `/admin/list/${current.data._id}`})
          res.push({label:'渠道管理', icon: <ShareAltOutlined />, key: `/channel/list/${current.data._id}`})
          res.push({label:'产品管理', icon: <CreditCardOutlined />, key: `/goods/list/${current.data._id}`})
          res.push({label:'订单管理', icon: <DollarOutlined />, key: '/orders/list'})
          break;
        case "channel":
          res.push({label:'订单管理', icon: <DollarOutlined />, key: '/orders/list'})
          break;
      }
      res.push({label:'账号中心', icon: <UserOutlined />, key: '/user/info'})
    } 
    return res;
  }

  showPageHeaderAvatar = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="header-collapse">
          <Tooltip title={"隐藏页头提示"} placement="left">
            <div
              className="collapse-header-collapse-border flex align-items-center justify-content-center"
              onClick={() => {
                Actions.collapseHeader("1");
              }}
            >
              <UpOutlined />
            </div>
          </Tooltip>
        </div>
      );
    }
    if (this.state.page_header === "1") {
      return (
        <div className="header-collapse">
          <Tooltip title={"显示页头提示"} placement="left">
            <div
              className="collapse-header-collapse-border flex align-items-center justify-content-center"
              onClick={() => {
                Actions.collapseHeader("0");
              }}
            >
              <DownOutlined />
            </div>
          </Tooltip>
        </div>
      );
    }
  };



  render() {
    return (
      <Layout>
        {/* 左侧菜单 */}
        <Sider
          width={"240"}
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          style={{ overflow: "auto", background: "#fff" }}
        >
          <div
            className="antd-pro-components-sider-menu-index-logo align-items-center"
            style={{
              paddingLeft: 24,
              height: 64,
              background: "#fff",
              position: "relative",
              borderBottom: "solid 1px #e8e8e8",
            }}
          >
            {this.state.collapsed ? null : (
              <span
                style={{
                  fontSize: 15,
                  lineHeight: "64px",
                  height: 64,
                  color: "#555",
                  fontWeight: 500,
                  paddingLeft: 14,
                }}
              >
                &nbsp;星鱼岛 | 运营管理后台
              </span>
            )}
          </div>
          <div style={{ padding: "2px 0" }}>
            <Menu
              onClick={this.menuClick}
              mode="inline"
              items={this.getLeftMenu()}
              selectedKeys={this.state.selectedMenuKeys}
              defaultOpenKeys={[]}
            > 
            </Menu>
          </div>
        </Sider>
        {/* 右侧主窗体 */}
        <Content style={{ overflow: "hidden" }}>
          {true ? (
            <Layout
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Header
                style={{
                  zIndex: 101,
                  flexGrow: 0,
                  background: "#fff",
                  padding: 0,
                  position: "relative",
                  borderBottom: "solid 1px #e8e8e8",
                }}
                className="space-between align-items-center"
              >
                {React.createElement(
                  this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => this.toggle(),
                  }
                )}

                <div>
                  <div
                    className={"antd-pro-components-global-header-index-right"}
                  >
                    <Dropdown overlay={this.getTopMenu()}>
                      <div
                        className={
                          "antd-pro-components-global-header-index-action"
                        }
                      >
                        <Space>
                          {this.state.name}
                        </Space>
                        <Avatar
                          style={{ marginLeft: 12, color: "#eee" }}
                          src={this.state.avatar}
                        />
                      </div>
                    </Dropdown>
                  </div>
                </div>

                {this.showPageHeaderAvatar()}
              </Header>
              <Content
                style={{ flexGrow: 1, overflow: "auto", background: "#fdfdfd" }}
              >
                <Switch>
                  {/* <Redirect exact from={"/"}  /> */}
                  {/* 企业 */}
                  <Route path="/ent/list" component={EntList} />
                  <Route path="/ent/create/:id" component={EntCreate} />
                  {/* 渠道 */}
                  <Route path="/channel/list/:entId" component={ChannelList} />
                  <Route path="/channel/create/:entId/:id" component={ChannelCreate} />
                  {/* 用户 */}
                  <Route path="/user/info" component={UserInfo} />
                  {/* 企业管理员 */}
                  <Route path="/admin/list/:entId" component={AdminList} />  
                  <Route path="/admin/create/:entId/:id" component={AdminCreate} />  
                  {/* 产品 */}
                  <Route path="/goods/list/:entId" component={GoodsList} />
                  <Route path="/goods/create/:entId/:id" component={GoodsCreate} />
                  {/* 期 */}
                  <Route path="/stage/list/:goodsId" component={StageList} />
                  <Route path="/stage/create/:goodsId/:id" component={StageCreate} />
                  {/* 票 */}
                  <Route path="/ticket/list/:stageId" component={TicketList} />
                   

                  <Route
                    path="/user/list/:portalId"
                    component={PortalUserList}
                  />
                  <Route
                    path="/user/create/:portalId/:id"
                    component={PortalUserCreate}
                  />
                  <Route
                    path="/role/list/:portalId"
                    component={PortalRoleList}
                  />
                  <Route
                    path="/user/create/:portalId/:id"
                    component={PortalUserCreate}
                  />
                  <Route
                    path="/role/create/:portalId/:id"
                    component={PortalRoleCreate}
                  />



                </Switch>
              </Content>
            </Layout>
          ) : null}
        </Content>
      </Layout>
    );
  }
}
