import Reflux from "reflux";
import Common from "../common";
import { Actions } from "./index";
const PortalActions = Reflux.createActions([
  "portalList", 
  "create",
  "update",
  "remove",
  "portal", 
  "portalUserList",
  "portalUserAdd",
  "portalUserRemove",
  "portalUserUpdateOwner",
  "portalOwnerApplyList",
  "portalOwnerApplyUpdateStatus",
  "portalRole",
  "portalRoleList",
  "portalRoleDefault",
  "portalRoleAdd",
  "portalRoleUpdate",
  "portalRoleRemove",
  "portalUserRoleChange",
  "bindHotel",
  "portalHotel"
]);

const PortalStore = Reflux.createStore({
  listenables: [PortalActions],

  onPortalOwnerApplyList:function(variables){
    let self = this;
    let query = `query(
      $status: SimpleStatus
      $page: Int!
      $size: Int!
    ){
      portalQuery{
        portalOwnerApplyList(
          status:$status
          page:$page
          size:$size
        ){
          total
          results{
            id
            message
            portalID
            userID
            type
            status
            createTime
            updateTime
            user{
              name
            }
            portal{
              name
            }
          }
        }
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalOwnerApplyList", json.data.portalQuery.portalOwnerApplyList);
      } else {
        Actions.globalMessage("error", error);
      }
    }); 
  },
  onPortalOwnerApplyUpdateStatus:function(variables){
    let self = this;
    let query = `mutation(
      $id: ID!
      $message: String!
      $status: SimpleStatus!
    ){
      portalMutation{
        portalOwnerApplyUpdateStatus(
          id:$id,
          message:$message,
          status:$status
        )
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalOwnerApplyUpdateStatus", json.data.portalMutation.portalOwnerApplyUpdateStatus);
      } else {
        Actions.globalMessage("error", error);
      }
    }); 

    
  },
  onPortalHotel:function(variables){
    let self = this;
    let query = `query($portalID:ID!){
      portalQuery{
        portalHotel(portalID:$portalID){
          hotelID
          hotel{
            id
            name
            cityName
            address
          }
        }
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalHotel", json.data.portalQuery.portalHotel);
      } else {
        Actions.globalMessage("error", error);
      }
    }); 
  },
  onPortalUserRoleChange:function(variables){
    let self = this;
    let query = `mutation(
      $portalID: ID!
      $id: ID!
      $roleID: ID!){
      portalMutation{
        portalUserRoleChange(portalID:$portalID, id:$id, roleID:$roleID)
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalUserRoleChange", json.data.portalMutation.portalUserRoleChange);
      } else {
        Actions.globalMessage("error", error);
      }
    }); 
  },
  onBindHotel:function(variables){
    let self = this;
    let query = `mutation($portalID:ID!, $hotelID:ID!){
      portalMutation{
        bindHotel(portalID:$portalID, hotelID:$hotelID)
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("bindHotel", json.data.portalMutation.bindHotel);
      } else {
        Actions.globalMessage("error", error);
      }
    }); 
  },

  onPortalRole: function (variables) {
    let self = this;
    let query = `query($portalID:ID!, $id:ID!){
      portalQuery{
        portalRole(portalID:$portalID, id:$id){
          id
          portalID
          name
          editable
          defaultable
          isDefault
          isSystem
          permissions
          createTime      
        }
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalRole", json.data.portalQuery.portalRole);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalRoleUpdate: function (variables) {
    let self = this;
    let query = `mutation($id:ID!, $portalID:ID!, $name:String!, $permissions: [Permission!]!){
      portalMutation{
        portalRoleUpdate(id:$id, portalID:$portalID, name:$name, permissions:$permissions )
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger(
          "portalRoleUpdate",
          json.data.portalMutation.portalRoleUpdate
        );
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalRoleAdd: function (variables) {
    let self = this;
    let query = `mutation($portalID:ID!, $name:String!, $permissions: [Permission!]!){
      portalMutation{
        portalRoleAdd(portalID:$portalID, name:$name, permissions:$permissions )
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger(
          "portalRoleAdd",
          json.data.portalMutation.portalRoleAdd
        );
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalRoleRemove: function (variables) {
    let self = this;
    let query = `mutation($portalID: ID!, $id: ID!){
      portalMutation{
        portalRoleRemove(portalID:$portalID,id:$id )
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger(
          "portalRoleRemove",
          json.data.portalMutation.portalRoleRemove
        );
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalUserRemove: function (variables) {
    let self = this;
    let query = `mutation($portalID: ID!, $id: ID!){
      portalMutation{
        portalUserRemove(portalID:$portalID,id:$id )
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger(
          "portalUserRemove",
          json.data.portalMutation.portalUserRemove
        );
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalUserAdd: function (variables) {
    let self = this;
    let query = `mutation($portalID: ID!, $identity: String!){
      portalMutation{
        portalUserAdd(portalID:$portalID,identity:$identity )
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalUserAdd", json.data.portalMutation.portalUserAdd);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalUserUpdateOwner: function (variables) {
    let self = this;
    let query = `mutation($portalID: ID!, $id: ID!){
      portalMutation{
        portalUserUpdateOwner(portalID:$portalID, id:$id)
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger(
          "portalUserUpdateOwner",
          json.data.portalMutation.portalUserUpdateOwner
        );
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalRoleDefault: function(variables){
    let self = this;
    let query = `mutation($portalID: ID!, $id: ID!){
      portalMutation{
        portalRoleDefault(portalID:$portalID, id:$id)
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger(
          "portalRoleDefault",
          json.data.portalMutation.portalRoleDefault
        );
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortal: function (variables) {
    let self = this;
    let query = `query($id:ID!){
      portalQuery{
        portal(id:$id){
          id
          name
          introduction
          type
          createTime      
        }
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portal", json.data.portalQuery.portal);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onCreate: function (variables) {
    let self = this;
    let query = `mutation($name:String!, $introduction:String!){
      portalMutation{
        create(name:$name, introduction:$introduction){
          id
        }
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("create", json.data.portalMutation.create);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },
  onUpdate: function (variables) {
    let self = this;
    let query = `mutation($id:ID!, $name:String!, $introduction:String!){
      portalMutation{
        update(id:$id, name:$name, introduction:$introduction) 
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("update", json.data.portalMutation.update);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },
  onRemove: function (variables) {
    let self = this;
    let query = `mutation($id:ID!){
      portalMutation{
        remove(id:$id) 
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("remove", json.data.portalMutation.remove);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalList: function (variables) {
    let self = this;
    let query = `query($keywords:String, $page:Int!, $size:Int!){
      portalQuery{
          portalList(keywords:$keywords, page:$page, size:$size){
          total
          results{
            id
            name
            introduction
            type
            createTime
          }
        }
      }
    }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalList", json.data.portalQuery.portalList);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalRoleList: function (variables) {
    let self = this;
    let query = `query($portalID:ID!){
        portalQuery{
          portalRoleList(
            portalID:$portalID 
          ){
            id
            name
            editable
            defaultable
            isDefault
            isSystem
            permissions
            createTime 
          }
        }
      }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalRoleList", json.data.portalQuery.portalRoleList);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onPortalUserList: function (variables) {
    let self = this;
    let query = `query($portalID:ID!, $keywords:String, $page:Int!, $size:Int!){
        portalQuery{
          portalUserList(
            portalID:$portalID
            keywords:$keywords
            page:$page
            size:$size
          ){
            total
            results{
              id
              portalID
              userID
              identity
              roleID
              isOwner
              isVirtual
              createTime
              updateTime
              portal{
                id
                name
                introduction
                type
              }
              role{
                id
                name
              }
              user{
                id
                name
                gender
                avatar
                profile{
                  introduction
                  position
                  birthday
                }
                email
                mobile
                identities{
                  name
                  type
                  extendName
                  isVerified
                }
              }
            }
          }
        }
      }`;
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("portalUserList", json.data.portalQuery.portalUserList);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },
});

export { PortalActions, PortalStore };
