import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Divider, Image, Popconfirm, Breadcrumb, Tag, Tooltip } from "antd";
import { Actions, Store } from "../../reflux/index";
export default class StageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_header: Store.collapseHeader,
      results: [],
      goodsId: props.match.params.goodsId,
      pageIndex: 1,
      pageSize: 10
    };
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentDidMount() {
    Actions.call('goods.single', { '_id': this.state.goodsId })
    Actions.call("stage.list", { goodsId: this.state.goodsId, pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
  }

  componentWillUnmount() {
    this.un_index();
  }

  columns = [
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      width: 100,
      render: (text, it) => {
        switch (it.state) {
          case -1: return <Tag color="#f50">注销</Tag>;
          case 0: return <Tag color="gold">待审核</Tag>;
          case 1: return <Tag color="#2db7f5">正常</Tag>;
        }
      }
    },
    {
      title: "开始时间",
      dataIndex: "begin",
      key: "begin",
      width:120,
    },
    {
      title: "结束时间",
      dataIndex: "end",
      key: "end",
      width:120,
    },
    {
      title: "价格",
      dataIndex: "price",
      key: "price",
      width:120,
    },{
      title: "第三方销售",
      dataIndex: "full_count",
      key: "full_count",
      render: (text, it) => {
        if (it.third_sales) {
          return '开启'
        }
      }
    },
    {
      title: "报名 / 成团 / 满团（人数）",
      dataIndex: "full_count",
      key: "full_count",
      width:200,
      render:(text, it)=>{
        return <Link to={`/ticket/list/${it._id}`}> <Space>
          <b >0</b>
          /
          <span>{it.base_count}</span>
          /
          <span>{it.full_count}</span>
        </Space></Link>
      }
    }, 
    {
      title: "操作",
      key: "op",
      width: 160,
      render: (text, record) => {
        switch (record.state) {
          case -1:
            return null;
          default:
            return <Space>
              <Popconfirm
                title="确定要下架此产品吗?"
                onConfirm={() => {
                  Actions.call("goods.delete", [record._id]);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">下架</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Link to={`/stage/create/${this.state.goodsId}/${record._id}`}>修改</Link>             
            </Space>
        }
      }
    }
  ];

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>产品管理</Breadcrumb.Item> 
            <Breadcrumb.Item>
              <Link to={`/goods/list/${this.state.entId}`}>产品列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>产品（{this.state.goodsName}）</Breadcrumb.Item> 
          </Breadcrumb>
          <h1>期列表</h1>
          产品（{this.state.goodsName}），可以分多期销售。
        </div>
      );
    }
  };

  onStatusChange = (type, data, page) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch (page) {
          case "stage.list":
            this.setState({ ...data.data });
            break;
          case "goods.single":
            this.setState({ goodsName: data.data.name, entId: data.data.entId });
            break;
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            <Link to={`/stage/create/${this.state.goodsId}/new`}>
              <Button className="margin-bootom-normal">添加期</Button>
            </Link>
          </div>
          <Table
            rowKey="_id"
            bordered
            columns={this.columns}
            dataSource={this.state.list}
          />
        </div>
      </div>
    );
  }
}
