import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Divider, Image, Popconfirm, Breadcrumb, Tag } from "antd";
import { Actions, Store } from "../../reflux/index";
export default class EntList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_header: Store.collapseHeader,
      results: [],
      pageIndex: 1,
      pageSize: 10
    };
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentDidMount() {
    Actions.call("ent.list", { pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
  }

  componentWillUnmount() {
    this.un_index();
  }

  columns = [
    {
      title: "状态",
      dataIndex: "state",
      key: "state",
      render: (text, it) => {
        switch (it.state) {
          case -1: return <Tag color="#f50">注销</Tag>;
          case 0: return <Tag color="gold">待审核</Tag>;
          case 1: return <Tag color="#2db7f5">正常</Tag>;
        }
      }
    },
    {
      title: "营业执照",
      dataIndex: "license",
      key: "license",
      width: 100,
      render: (text, it) => {
        if (text !== undefined) {
          return <Image style={{ maxHeight: 24 }} src={text} />
        }
      }
    },
    {
      title: "企业名称",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "法人",
      dataIndex: "corporation",
      key: "corporation"
    },
    {
      title: "身份证号",
      dataIndex: "idcard",
      key: "idcard"
    },
    {
      title: "企业地址",
      dataIndex: "address",
      key: "address"
    },
    {
      title: "联系电话",
      dataIndex: "mobile",
      key: "mobile"
    }, 
    {
      title: "管理",
      key: 'manager',
      render: (text, record) => {
        return <Space>
          <Link to={`/admin/list/${record._id}`}>员工</Link>
          <Divider type="vertical" />
          <Link to={`/channel/list/${record._id}`}>渠道</Link>
          <Divider type="vertical" />
          <Link to={`/goods/list/${record._id}`}>产品</Link>
        </Space>
      }
    },
    {
      title: "操作",
      key: "op",
      width: 160,
      render: (text, record) => {
        switch (record.state) {
          case -1:
            return null;
          default:
            return <Space>
              <Popconfirm
                title="确定注销此企业吗?"
                onConfirm={() => {
                  Actions.call("ent.delete", [record._id]);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">注销</a>
              </Popconfirm>
              <Divider type="vertical" />
              <Link to={`/ent/create/${record._id}`}>修改</Link>


            </Space>
        }
      }
    }
  ];

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>企业管理</Breadcrumb.Item>
          </Breadcrumb>
          <h1>企业列表</h1>
          企业可以创建产品、可以为产品添加分期，可以添加渠道。企业是产品、渠道、订单的根基。
        </div>
      );
    }
  };

  onStatusChange = (type, data, page) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch (page) {
          case "ent.list":
            this.setState({ ...data.data });
            break;
          case "ent.delete":
            Actions.call("ent.list", { pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
            break;
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            <Link to={`/ent/create/new`}>
              <Button className="margin-bootom-normal">添加企业</Button>
            </Link>
          </div>
          <Table
            rowKey="_id"
            bordered
            columns={this.columns}
            dataSource={this.state.list}
          />
        </div>
      </div>
    );
  }
}
