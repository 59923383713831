import Reflux from "reflux";
import Common from "../common";
import moment from 'moment';
import { Actions } from "./index";
const MessageActions = Reflux.createActions(["orderInvoice", "orderPaid", "orderRefund"]);

const MessageStore = Reflux.createStore({
  listenables: [MessageActions],
  
  onOrderRefund: function () {
    let self = this;
    let query = `query( 
            $page:Int!
            $size:Int!
          ){
            orderQuery{
              orderList(   
                refundStatus: [REQUEST] 
                page:$page
                size:$size
              ){
                count
                 
              }
            }
          }`;
    let variables = { page: 1, size: 1 };
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("orderRefund", json.data.orderQuery.orderList.count); 
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onOrderPaid: function () {
    let self = this;
    let query = `query( 
            $page:Int!
            $size:Int!
          ){
            orderQuery{
              orderList(  
                status:[PAID]  
                page:$page
                size:$size
              ){
                count
                list{
                    id
                    createTime
                    hotelId 
                    hotel{
                    name  
                    }
                    user{
                    fullName
                    }
                }
              }
            }
          }`;
    let variables = { page: 1, size: 1 };
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      
      if (httpStatus === 200) {
        let show = false;
        
        if (json.data.orderQuery.orderList.count > 0){
            if(self.orderPaid === undefined ){
              show = true
                self.orderPaid = json.data.orderQuery.orderList.list[0]; 
            }else{  
                let paidCrateTime = moment(self.orderPaid.createTime)  
                let newCreateTime = moment(json.data.orderQuery.orderList.list[0].createTime);
                show = newCreateTime > paidCrateTime
                self.orderPaid = json.data.orderQuery.orderList.list[0]; 
            }
        } 
        
        self.trigger("orderPaid", show);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },
  onOrderInvoice: function () {
    let self = this;
    let query = `query( 
            $page:Int!
            $size:Int!
          ){
            orderQuery{
              orderList( 
                invoiceExecuted:false
                page:$page
                size:$size
              ){
                count
              }
            }
          }`;
    let variables = { page: 1, size: 1 };
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("orderInvoice", json.data.orderQuery.orderList.count);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },
});

export { MessageActions, MessageStore };
