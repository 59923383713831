import React, { Component } from "react";
import { Link } from "react-router-dom"; 
import { Actions, Store } from "../../reflux/index";
import BrowserHistory from "../../history/browserhistory"; 
import Logo from "../../components/logo";
import { Input, Form, Breadcrumb, Button,  Space,Alert } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons"; 
export default class AdminCreate extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      id: props.match.params.id,
      entId:props.match.params.entId,
      page_header: Store.collapseHeader
    }; 
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentWillUnmount() { 
    this.un_index();
  }

  componentDidMount() {
    if (this.state.id !== "new") {
      Actions.call("user.single",{ _id: this.state.id });
    }
  }

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>员工管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/ent/list">员工列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.state.id === "new" ? "新增" : ""}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>员工信息</h1>
          <span>
            {this.state.id === "new" ? "新增" : "修改"}
            企业员工。
          </span>
        </div>
      );
    }
  };

  onStatusChange = (type, data, module) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch(module){
          case "user.create_ent_user":
          case "user.create":
          case "user.update":
            if(data.code === 200){
              Actions.globalMessage("success", "数据保存完成！");
              setTimeout(() => {
                BrowserHistory.push(`/admin/list/${this.state.entId}`);
              }, 500);
            }else{
              Actions.globalMessage("error", data.data.message);
            } 
            break;
          case "user.single":
            this.setState({license:data.data.license});
            this.refs.form.setFieldsValue(data.data);
            break;
          default:break;
        }break; 
       
      default:
        break;
    }
  };

  onFinish = values => { 
    if (this.state.id === "new") { 
      values.entId = this.state.entId
      values.state = 0
      Actions.call("user.create_ent_user", values);
    } else {
      values._id = this.state.id;
      Actions.call("user.update",values);
    }
  };

  // 相关附件table头
  columns = [
    {
      title: "名称",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "操作",
      key: "op",
      width: 200,
      dataIndex: "op",
      render: (text, record) => {
        return <Space></Space>;
      }
    }
  ];

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div className="p16">
          <div className="ant-card ant-card-bordered margin-bootom-normal">
            {/*  ---------基础信息--------- */}
            <div className="ant-card-head">
              <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title">基础信息</div>
              </div>
            </div>
            <div className="mt-20px">
              <Form
                ref="form"
                initialValues={{ visible: true }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="name"
                  label={<span>员工姓名</span>}
                  rules={[{ required: true, message: "请输入员工姓名!" }]}
                >
                  <Input className="w-400px" placeholder="请输入员工姓名" />
                </Form.Item>
                <Form.Item noStyle > 
                  <Space >
                    <Form.Item label={<span>联系电话</span>}  name="mobile" rules={[
                      { required: true, message: "请输入联系电话!" },
                      { pattern:new RegExp('^1[3578]\\d{9}$','g'),message:'请检查手机号是否正确！'}]}>
                    <Input disabled={this.state.id!=='new'} className="w-400px" placeholder="请输入联系电话" />
                    </Form.Item>
                    <div className="ant-form-item">
                    <Alert message='联系电话将作为员工的登录账号' type='info'  showIcon 
                    style={{marginLeft:8, border:0, padding:0, background:'#fff', color:'#ccc !important'}} />
                    </div>
                  </Space> 
                </Form.Item> 
                <Form.Item
                  name="idcard"
                  label={<span>身份证号</span>}
                  rules={[
                    { required: false, message: "请输入员工身份证号!" },
                    { pattern:new RegExp('^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$','g'),message:'请检查身份证号是否正确！'}]}
                >
                  <Input className="w-400px" placeholder="请输入员工身份证号" />
                </Form.Item> 
                <Form.Item
                name="password"
                label={<span>初始密码</span>}
                >
                  <Input.Password autoComplete="new-password" className="w-400px" />
                </Form.Item>
                <Form.Item
                  name="avatar"
                  label={<span>头像</span>}
                  rules={[{ required: false, message: "请上传员工头像!" }]}
                >
                  <Logo
                    change={(osspath, url) =>{ 
                        this.setState({ osspath, license: url })
                      } 
                    } 
                    dir={`ent/${this.state.entId}/`}
                    type="COMMON"
                    photos={[{ id: this.state.license, path: this.state.license }]}
                  />
                </Form.Item>
              </Form>
            </div>
            {/*  ---------保存--------- */}
            <div className="p24 border-top-1px">
              <Space>
                <Link to="/ent/list">
                  <Button icon={<ArrowLeftOutlined />}>取消</Button>
                </Link>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.refs.form.submit();
                  }}
                >
                  保存
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
