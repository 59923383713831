import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PortalActions, PortalStore } from "../../reflux/portal";
import { Actions, Store } from "../../reflux/index";
import BrowserHistory from "../../history/browserhistory"; 
import Logo from "../../components/logo";
import { Input, Form, Breadcrumb, Button, InputNumber, Alert, Space, Radio } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons"; 
export default class ChannelCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      register_type:'user',
      entId:props.match.params.entId,
      id: props.match.params.id,
      page_header: Store.collapseHeader
    }; 
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentWillUnmount() { 
    this.un_index();
  }

  componentDidMount() {
    if (this.state.id !== "new") {
      Actions.call("channel.single",{ _id: this.state.id });
    }
  }

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>渠道管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/channel/list/${this.state.entId}`}>渠道列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.state.id === "new" ? "新增" : ""}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>渠道信息</h1>
          <span>
            {this.state.id === "new" ? "新增" : "修改"}
            渠道。
          </span>
        </div>
      );
    }
  };

  onStatusChange = (type, data, module) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch(module){
          case "channel.create":
          case "channel.update":
            if(data.code === 200){
              Actions.globalMessage("success", "数据保存完成！");
              setTimeout(() => {
                BrowserHistory.push(`/channel/list/${this.state.entId}`);
              }, 500);
            }else{
              Actions.globalMessage("error", data.data.message);
            } 
            break;
          case "channel.single":
            this.setState({license:data.data.license,register_type:data.data.register_type});
            this.refs.form.setFieldsValue(data.data);
            break;
          default:break;
        }break; 
       
      default:
        break;
    }
  };

  onFinish = values => {
    values.entId = this.state.entId;
    if(this.state.license !==undefined){
      values.license = this.state.license;
    }
    if (this.state.id === "new") {
      Actions.call("channel.create", values);
    } else {
      values._id = this.state.id;
      Actions.call("channel.update",values);
    }
  };

  // 相关附件table头
  columns = [
    {
      title: "名称",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "操作",
      key: "op",
      width: 200,
      dataIndex: "op",
      render: (text, record) => {
        return <Space></Space>;
      }
    }
  ];

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div className="p16">
          <div className="ant-card ant-card-bordered margin-bootom-normal">
            {/*  ---------基础信息--------- */}
            <div className="ant-card-head">
              <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title">基础信息</div>
              </div>
            </div>
            <div className="mt-20px">
              <Form
                ref="form"
                initialValues={{ register_type: 'user', level:0 }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="name"
                  label={<span>渠道名称</span>}
                  rules={[{ required: true, message: "请输入渠道名称!" }]}
                >
                  <Input className="w-400px" placeholder="请输入渠道名称" />
                </Form.Item>
                <Form.Item
                  name="register_type"
                  label={<span>渠道类型</span>}
                  rules={[{ required: true, message: "请选择渠道类型!" }]}
                >
                  <Radio.Group onChange={(e)=>{
                    this.setState({'register_type':e.target.value})}
                    } > 
                    <Radio value="user">个人</Radio> 
                    <Radio value="ent">企业</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item noStyle > 
                  <Space >
                    <Form.Item label={<span>联系电话</span>}  name="mobile" rules={[
                      { required: true, message: "请输入联系电话!" },
                      { pattern:new RegExp('^1[3578]\\d{9}$','g'),message:'请检查手机号是否正确！'}]}>
                    <Input disabled={this.state.id!=='new'} className="w-400px" placeholder="请输入联系电话" />
                    </Form.Item>
                    <div className="ant-form-item">
                    <Alert message='联系电话将作为渠道的登录账号' type='info'  showIcon 
                    style={{marginLeft:8, border:0, padding:0, background:'#fff', color:'#ccc !important'}} />
                    </div>
                  </Space>
                </Form.Item>
                <Form.Item
                  name="idcard"
                  label={<span>身份证号</span>}
                  rules={[{ required: true, message: "请输入身份证号!" },
                  { pattern:new RegExp('^[1-9]\\d{5}(18|19|20)\\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$','g'),message:'请检查身份证号是否正确！'}]}
                >
                  <Input className="w-400px" placeholder="请输入身份证号" />
                </Form.Item>
                {this.state.register_type === 'ent' ? <Form.Item
                  name="license"
                  label={<span>营业执照</span>}
                  rules={[{ required: false, message: "请输入企业营业执照!" }]}
                >
                  <Logo
                    change={(osspath, url) =>{ 
                        this.setState({ osspath, license: url })
                      } 
                    } 
                    dir="ent/"
                    type="COMMON"
                    photos={[{ id: this.state.license, path: this.state.license }]}
                  />
                </Form.Item>:null} 

                <Form.Item 
                  label={<span>渠道等级</span>} 
                > 
                  <Space>
                    <Form.Item noStyle name="level" rules={[{ required: true, message: "请选择渠道等级!" }]}>
                      <Radio.Group className="w-400px"> 
                        <Radio value={0}>0级</Radio> 
                        <Radio value={1}>1级</Radio>
                        <Radio value={2}>2级</Radio>
                        <Radio value={3}>3级</Radio>
                      </Radio.Group>
                    </Form.Item>
                 
                    <Alert message='渠道等级影响分成金额，参考产品信息中分成配置' type='info'  showIcon 
                    style={{marginLeft:8, border:0, padding:0, background:'#fff', color:'#ccc !important'}} />
                  </Space>
                </Form.Item>  
              </Form>
            </div>
            {/*  ---------保存--------- */}
            <div className="p24 border-top-1px">
              <Space>
                <Link to={`/channel/list/${this.state.entId}`}>
                  <Button icon={<ArrowLeftOutlined />}>取消</Button>
                </Link>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.refs.form.submit();
                  }}
                >
                  保存
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
