import React from "react";
import Common from '../../common';
import { Redirect, Route, Switch, Link } from "react-router-dom";
import PasswordForm from "./password";

export default class Sign extends React.Component {
  constructor(props) {
    super(props); 
    Common.clearCurrentUserType();
  }
  render() {
    return (
      <div className="sign">
        <div>
          <div className="xzg_label flex flex-direction-column align-items-center ">
            <span style={{ fontSize: 48 }}>星鱼岛</span>
            <span style={{ fontSize: 14 }}> ---点亮科技之星</span>
          </div>

          <div className="sign-inner">
            <div className="sign-border">
              <div
                className="row-content-center"
                style={{
                  fontFamily: "PingFangSC-Regular,PingFang SC",
                  fontSize: "24px",
                  textAlign: "center",
                  paddingTop: "20px",
                  fontWeight: 400,
                }}
              ></div>
              <div style={{ padding: "10px 40px" }}>
                <Switch>
                  <Redirect exact from={"/signin"} to={"/signin/password"} />
                  <Route path="/signin/password" component={PasswordForm} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-inner">
          <div>copyright @2021 星翼环宇（北京）科技有限公司 出品</div>
        </div>
      </div>
    );
  }
}
