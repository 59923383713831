// "sync": "47.96.16.183:9000",
let development = { 
  host: "http://localhost:8000", // 开发环境api地址 
  oss: "//xingyudao.oss-cn-beijing.aliyuncs.com"
};
// "sync": "101.37.252.49:9000",
let production = { 
  host: "", // 正式环境api地址 
  oss: "//xingyudao.oss-cn-beijing.aliyuncs.com"
};

function getConfig() {
  if (process.env.NODE_ENV === "development") {
    return development;
  } else { 
    return production;
  }
}

export default getConfig;
