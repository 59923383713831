import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Space, Table, Divider, Image, Popconfirm, Breadcrumb ,Tag} from "antd";
import { Actions, Store } from "../../reflux/index";
export default class AdminList extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      entId:props.match.params.entId,
      list:[],
      pageIndex: 1,
      pageSize: 10,
      page_header: Store.collapseHeader
    };
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentDidMount() {
    Actions.call('user.list',{
      entId:this.state.entId,
      pageIndex: this.state.pageIndex, 
      pageSize: this.state.pageSize
    });
  }

  componentWillUnmount() {
    this.un_index(); // 解绑定，酒店
  }

  

  columns = [ 
    {
      title: "头像",
      dataIndex: "avatar",
      key: "avatar",
      width:100,
      render:(text, it)=>{
        if(text!==undefined){
          return <Image style={{maxHeight:24}} src={text}  />
        }
      }
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    }, 
    {
      title: "手机号",
      dataIndex: "mobile",
       
    },
    {
      title: "微信昵称",
      dataIndex: "fullName",
      render: (text, record) => {
        
      },
    },
    {
      title: "身份证号",
      dataIndex: "idcard", 
    },
    {
      title: "创建时间",
      dataIndex: "createTime", 
    }, 
    {
      title: "操作",
      key: "op",
      width: 100,
      render: (text, record) => {
        let self = this;
        return (
          <Space>
            <Popconfirm
              title="确定要删除此员工吗?"
              onConfirm={()=>{
                Actions.call("user.delete_ent_user", {"id":record._id, "entId":this.state.entId});
              }} 
              okText="Yes"
              cancelText="No"
            >
              <a href="#">删除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  onStatusChange = (type, data, page) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "call":
        switch (page) {
          case "user.list":
            this.setState({ ...data.data });
            break;
          case "user.delete_ent_user":
            Actions.call("user.list", {entId:this.state.entId, pageIndex: this.state.pageIndex, pageSize: this.state.pageSize });
            break;
        }
        break;
      default:
        break;
    }
  };

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>员工管理</Breadcrumb.Item>
          </Breadcrumb>
          <h1>员工列表</h1>
         。
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            <Link to={`/admin/create/${this.state.entId}/new`}>
              <Button className="margin-bootom-normal">添加员工</Button>
            </Link>
          </div>
          <Table
            rowKey="_id"
            bordered
            columns={this.columns}
            dataSource={this.state.list}
          />
        </div>
      </div>
    );
  }
}
