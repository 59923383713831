import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PortalActions, PortalStore } from "../../reflux/portal";
import { Actions, Store } from "../../reflux/index";
import BrowserHistory from "../../history/browserhistory";
import Logo from "../../components/logo";
import { Input, Form, Breadcrumb, Button, InputNumber, Alert, Space, Radio, message } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
export default class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      changePassword:false,
      page_header: Store.collapseHeader
    };
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentWillUnmount() {
    this.un_index();
  }

  componentDidMount() {
    Actions.viewer();
  }


  onStatusChange = (type, data, module) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "viewer": // 获取当前用户 
        if (data.code !== 200) {
          BrowserHistory.push("/signin"); // 去登录
        } else {
          if (data.data.avatar !== undefined && data.data.avatar !== '') {
            let photos = [{ id: data.data.avatar, path: data.data.avatar }]
            this.setState({ photos })
          }
          this.refs.form.setFieldsValue(data.data);
          this.setState({ _id: data.data._id, mobile: data.data.mobile });
        }
        break;
      case "call":
        switch (module) {
          case 'user.update':
            message.success('用户信息已保存')
            Actions.viewer();
          default: break;
        }break;

      default:
        break;
    }
  };

  onFinish = values => {
    if(this.state.changePassword){
      let data = {"_id":this.state._id, "password":values.password, "name":values.name}
      Actions.call("user.update", data);
    }else{
      let data = {"_id":this.state._id, "name":values.name}
      Actions.call("user.update", data);
    }
  };

  // 相关附件table头
  columns = [
    {
      title: "名称",
      key: "name",
      dataIndex: "name"
    },
    {
      title: "操作",
      key: "op",
      width: 200,
      dataIndex: "op",
      render: (text, record) => {
        return <Space></Space>;
      }
    }
  ];

  render() {
    return (
      <div>
        <div className="p16">
          <div className="ant-card ant-card-bordered margin-bootom-normal">
            {/*  ---------基础信息--------- */}
            <div className="ant-card-head">
              <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title">基础信息</div>
              </div>
            </div>
            <div className="mt-20px">
              <Form
                ref="form"
                initialValues={{}}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="license"
                  label={<span>头像</span>}
                >
                  <Logo
                    change={(osspath, url) => {
                      let photos = [{ id: url, path: url }]
                      this.setState({ photos })
                      Actions.call('user.update', { _id: this.state._id, avatar: url })
                    }
                    }
                    dir="user/"
                    photos={this.state.photos}
                  />
                </Form.Item>
                <Form.Item noStyle >
                  <Space >
                    <Form.Item label={<span>登录账号</span>} name="mobile" >
                      <Input disabled className="w-400px" placeholder="" />
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Form.Item
                  name="name"
                  label={<span>名称</span>}
                >
                  <Input className="w-400px" placeholder="" />
                </Form.Item>
                {this.state.changePassword ? null : <Form.Item label="密码">
                  <a href="#" onClick={()=>{this.setState({changePassword:true})}}>修改密码</a>
                </Form.Item>}
                {this.state.changePassword ? <Form.Item
                  name="password"
                  label="密码"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的密码!',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password className="w-400px" />
                </Form.Item>:null}
                {this.state.changePassword ? <Form.Item
                  name="confirm"
                  label="确认密码"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '请确认您的密码!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('两个密码不一致!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password className="w-400px" />
                </Form.Item>:null}


              </Form>
            </div>
            <div className="p24 border-top-1px">
              <Space> 
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.refs.form.submit();
                  }}
                >
                  保存
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
