import React, { Component } from "react"; 
import {Button, Upload } from "antd"; 
import { ContentUtils } from 'braft-utils';
import Common from "../common/index";
import getConfig from "../config/index";
import { MessageOutlined ,FileImageOutlined } from "@ant-design/icons"; 
// 引入编辑器组件
import BraftEditor from 'braft-editor';
// 引入编辑器样式
import 'braft-editor/dist/index.css';

export default class XyEditor extends Component {
  constructor(props) {
    super(props); 
    let ss = `{"blocks":[{"key":"19biv","text":"aa","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"7a5hn","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"49gb1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"6im6e","text":" ","type":"atomic","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":1,"key":0}],"data":{}},{"key":"df40p","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"1eoni","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"finiu","text":"func call(){","type":"code-block","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"1f4h5","text":"}","type":"code-block","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"IMAGE","mutability":"IMMUTABLE","data":{"url":"http://xingyudao.oss-cn-beijing.aliyuncs.com/goods/6347d1162afff30b033f067a/1666258946393.png","type":"IMAGE","width":"100%","height":"auto"}}}}`
    let editorState = BraftEditor.createEditorState(null); 
    
    this.state = {dir:props.dir, editorState , raw:'' }; 
  }

  componentDidMount() { 
  }
  componentWillReceiveProps(props) { 
    if (props.dir !== null) {
      this.setState({ dir:props.dir });
    }
    if(props.raw !== this.state.raw){
        this.setState({ raw:props.raw }); 
        let editorState = BraftEditor.createEditorState(props.raw); 
        this.editorInstance.setValue(editorState); 
        //this.props.onChange(editorState) 
    }
  }
  componentWillUnmount() {
     
  } 
  handleEditorChange = (editorState) => {
    this.setState({ editorState })
    this.props.onChange(editorState) 
  }
  submitContent = async () => {
    // 在编辑器获得焦点时按下ctrl+s会执行此方法
    // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
    const htmlContent = this.state.editorState.toHTML()
    //const result = await saveEditorContent(htmlContent)
  }
  imageControls = [
    {
      "text": "100%",
      "render": (mediaData) => { 
        mediaData.width = '100%'
        mediaData.height = 'auto' 
      },
      "onClick": (block) => {
        console.log(block)
      }
    }
  ] 
  getExtraData = file => {
    let OSSData = this.state.OSSData; 
    let res = {
      key: file.url,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy, 
      Signature: OSSData.signature
    }; 
    return res;
  };
  beforeUpload = async file => {
    let res = await Common.upToken(file.name, this.state.dir); 
    this.state.OSSData = res.data; 
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = this.state.OSSData.dir + filename; 
    file.oss = this.state.OSSData.host;
    return file; 
  };

   /** 图片变更 */
   onChange = ({ file, fileList }) => {   
    if(file.status === "done"){
        let url = file.oss + '/' + file.url;   
        let editorState = ContentUtils.insertMedias(this.state.editorState, [{
            type: 'IMAGE',
            url: url
          }]);
        this.setState({editorState});
    } 
  };

  render() { 
    const config = getConfig();

    const props = {
      name: "file",
      accept:"image/*",
      showUploadList: false,
      multiple: false,  
      action: config.oss,
      onChange: this.onChange,
      data: this.getExtraData,
      beforeUpload: this.beforeUpload
    }; 
    return (<BraftEditor 
      ref={instance => this.editorInstance = instance}
        value={this.state.editorState}
        onChange={this.handleEditorChange}
        onSave={this.submitContent}
        imageControls={this.imageControls } 
        controls={[
            'undo', 'redo', 'separator',
            'headings','font-size', 'line-height', 'letter-spacing', 'separator', 
            'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
             'text-indent', 'text-align', 'separator',
             'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
            'hr',  'separator',  'emoji', 
        ]}
        extendControls={[
          {
            key: 'antd-uploader',
            type: 'component',
            component: (
              <Upload {...props}>
                {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                <button type="button" className="control-item button upload-button" data-title="插入图片">
                  <FileImageOutlined style={{fontSize:16}} />
                </button>
              </Upload>
            )
          }
        ]}  
      />);
  }
}
