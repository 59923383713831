import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/app/index";
import * as serviceWorker from "./serviceWorker";
import { Route, Router, Switch } from "react-router-dom";
import BrowserHistory from "./history/browserhistory";

import Sign from "./pages/sign/index";
import moment from "moment";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Router history={BrowserHistory}>
      <Switch>
        <Route strict path="/signin" component={Sign} />
        <Route strict path="/" component={App} />
      </Switch>
    </Router>
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
