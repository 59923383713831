import Reflux from "reflux";
import Common from "../common";
import getConfig from "../config";

const Config = getConfig();

const Actions = Reflux.createActions([
  "signIn",
  "sendCode",
  "signOut",
  "globalMessage",
  "viewer",
  "tcMonitor",
  "collapseHeader",
  "call",
  "uploadToken"
]);

const Store = Reflux.createStore({
  listenables: [Actions],
  collapseHeader: "0",
  user: { portalUsers: [] },
  portal: null,
  onSendCode:function(variables){
    let self = this;
    let query = `query($identity:String!, $exist:Boolean!, $captcha:Captcha!){
      userQuery{
        sendCode(
          identity:$identity
          exist:$exist
          captcha:$captcha
        )
      }
    }`;

    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("sendCode", json.data.userQuery.sendCode);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onUploadToken: function (fileName) {
    let self = this;
    let query = `query($fileName:String!){
      userQuery{
        uploadToken(fileName:$fileName){
          path
          token
        }
      }
    }`;
    let variables = { fileName: fileName };
    Common.graphql({ query, variables }, (json, error, httpStatus) => {
      if (httpStatus === 200) {
        self.trigger("uploadToken", json.data.userQuery.uploadToken);
      } else {
        Actions.globalMessage("error", error);
      }
    });
  },

  onCall: function (module, params) {
    Common.post(
      `${Config.host}/api/call/${module}`,
      params,
      (data, error, status) => {
        if (status === 200) {
          this.trigger("call", data, module, params);
        }
      }
    );
  },

  onCollapseHeader: function (value) {
    this.collapseHeader = value;
    this.trigger("collapseHeader", value);
  },
  onSignOut:function(params){
    Common.post(
      `${Config.host}/api/signout`,
      params,
      (data, error, status) => {
        if (status === 200) {
          this.trigger("signOut", data);
        }
      }
    );
  },
  onSignIn: function (params) {
    Common.post(
      `${Config.host}/api/signin`,
      params,
      (data, error, status) => {
        if (status === 200) {
          this.trigger("signIn", data);
        }
      }
    );
  },

  onViewer: function (params) {
    Common.post(
      `${Config.host}/api/viewer`,
      params,
      (data, error, status) => {
        if (status === 200) {
          this.trigger("viewer", data);
        }
      }
    );
  },

  onGlobalMessage: function (type, message) {
    if (message === "" || message === null || message === undefined) {
      message = "未知异常！";
    } 
    this.trigger("global-message", { type: type, message: message });
  },

  onTcMonitor: function (data) {
    this.trigger("tcMonitor", data);
  }
});

export { Actions, Store };
