import React from "react";
import BrowserHistory from '../../history/browserhistory';
import { Form, Input, Button , message} from "antd";
import { 
  MobileOutlined,
  UnlockOutlined,
} from "@ant-design/icons"; 
import Common from '../../common';
import { Actions, Store } from "../../reflux/index";
import "./index.css";

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.unsubscribe = Store.listen(this.onStatusChange.bind(this));
    this.state = { timeout: 0 }; 
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  onStatusChange = (type, data) => { 
    switch (type) {
      case "global-message":
          if(data.message === 'password error'){
            message.error('密码不正确！')
          } 
          else if(data.message === 'mongo: no documents in result'){
            message.error('用户不存在！')
          }else{
            message.error(data.message)
          }
          break;
      case "signIn":
        if(data.code === 200){  
          // 有渠道，设置当前渠道
          if (data.data.channel !== undefined){
            if(data.data.channel.length > 0){
              Common.setCurrentUserType('channel', data.data.channel[0])
            }
          }
          // 有企业，设置当前企业
          if (data.data.ent !== undefined){
            if(data.data.ent.length > 0){
              Common.setCurrentUserType('ent', data.data.ent[0])
            }
          }
          BrowserHistory.push(`/`);
        }else{
          Actions.globalMessage("error", data.data.message);
        } 
        break;
      default:
        break;
    }
  };

  handleSubmit = (e) => {
    Actions.signIn(e);
  };

  render() {
    return (
      <div style={{ paddingTop: 20 }}>
        <div className="account-section-title"><p className="account-section-title-text">欢迎使用</p> </div>
        
        <Form onFinish={this.handleSubmit} className="login-form">
          <Form.Item
            required
            name="mobile"
            tooltip="Please input your login name!"
          >
            <Input
              size="large"
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
              style={{borderLeft:0,borderTop:0, borderRight:0, paddingleft:12,}}
              prefix={<MobileOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="输入手机号"
            />
          </Form.Item>
          <Form.Item name="password">
            <Input.Password
              size="large"
              type="password"
              required
              autoComplete="new-password"
              tooltip="Please input your password!"
              onChange={(e) => {
                this.setState({ mobile: e.target.value });
              }}
              style={{borderLeft:0,borderTop:0, borderRight:0, paddingleft:12,}}
              prefix={<UnlockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="输入密码"
            />
          </Form.Item>
          <Form.Item> 
            <div className="space-around">
              <Button
                style={{ height: 48, margin: 4 }}
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                登 录
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default PasswordForm;
