import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PortalActions, PortalStore } from "../../../reflux/portal";
import { Actions, Store } from "../../../reflux/index";
import BrowserHistory from "../../../history/browserhistory"; 
import {
  Input,
  Form,
  Breadcrumb, 
  Button,
  Row,
  Col,
  Checkbox, 
  Space,
} from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons"; 
export default class PortalRoleCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operations: [],
      portalId: props.match.params.portalId,
      id: props.match.params.id,
      page_header: Store.collapseHeader,
    };
    this.un_store = PortalStore.listen(this.onStatusChange);
    this.un_index = Store.listen(this.onStatusChange);
  }

  componentWillUnmount() {
    this.un_store();
    this.un_index();
  }

  componentDidMount() {
    if (this.state.id !== "new") { 
      PortalActions.portal({id:this.state.portalId})
      PortalActions.portalRole({id:this.state.id, portalID: this.state.portalId });
    }
  }

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>小店管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/portal/list">小店列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/role/list/${this.state.portalId}`}>角色列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.state.id === "new" ? "新增" : ""}
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1>角色信息</h1>
          <span>
            {this.state.id === "new" ? "新增" : "修改"}
            角色信息
          </span>
        </div>
      );
    }
  };

  onStatusChange = (type, data, module) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "portal":
        console.log('data', data)
        this.setState({type:data.type}); 
        break;
      case "portalRole":
        this.setState({operations:data.permissions});
        this.refs.form.setFieldsValue(data);
        break;
      case "portalRoleUpdate":
      case "portalRoleAdd":
        Actions.globalMessage("success", "数据保存完成！");
        setTimeout(() => {
          BrowserHistory.push(`/role/list/${this.state.portalId}`);
        }, 500);
        break;
      default:
        break;
    }
  };

  onFinish = (values) => {
    values.permissions = this.state.operations;
    values.portalID = this.state.portalId;  
    if (this.state.id === "new") {  
      PortalActions.portalRoleAdd(values);
    } else {
      values.id = this.state.id;
      PortalActions.portalRoleUpdate(values);
    }
  };

   

  // 优惠券："查看优惠券" VOUCHER_MAKER_VIEW、"创建或发放优惠券" VOUCHER_MAKER_EMIT
  operations_voucher = ["VOUCHER_MAKER_VIEW","VOUCHER_MAKER_EMIT"]
  // 订单： "查看订单" ORDER_VIEW、"处理订单" ORDER_UPDATE
  operations_order = ["ORDER_VIEW","ORDER_UPDATE"]
  // 产品： "查看产品" PRODUCT_VIEW、"创建或更新产品" PRODUCT_UPDATE、"删除产品" PRODUCT_REMOVE
  operations_product = ["PRODUCT_VIEW","PRODUCT_UPDATE","PRODUCT_REMOVE"]
  // 角色： "查看小店角色" PORTAL_ROLE_VIEW、"添加小店角色" PORTAL_ROLE_ADD
  // 角色： "修改小店角色" PORTAL_ROLE_UPDATE、"删除小店角色" PORTAL_ROLE_REMOVE
  operations_role = ["PORTAL_ROLE_VIEW","PORTAL_ROLE_ADD","PORTAL_ROLE_UPDATE","PORTAL_ROLE_REMOVE"]
  // 用户： "查看小店用户" PORTAL_USER_VIEW、添加小店用户" PORTAL_USER_ADD、
  // 用户： "移除小店用户" PORTAL_USER_REMOVE、"设置小店用户角色" PORTAL_USER_ROLE
  operations_user = ["PORTAL_USER_VIEW","PORTAL_USER_ADD","PORTAL_USER_REMOVE","PORTAL_USER_ROLE"]
  // 小店： "查看小店" PORTAL_VIEW、"设置小店" PORTAL_UPDATE、"删除小店" PORTAL_REMOVE
  operations_portal = ["PORTAL_VIEW", "PORTAL_UPDATE","PORTAL_REMOVE"]
  // 超管的权限：管理HOTEL权限 SUPER_HOTEL、管理支付订单权限 SUPER_PAYMENT、管理订单 SUPER_ORDER
  // 超管的权限：管理任务权限 SUPER_TASK、管理优惠券生成器权限 SUPER_VOUCHER_MAKER、管理service权限 SUPER_SERVICE
  // 超管的权限：管理所有产品 SUPER_PRODUCT、管理所有站点 SUPER_PORTAL、管理所有站点用户 SUPER_PORTAL_USER
  // 超管的权限：管理所有站点角色 SUPER_PORTAL_ROLE
  operations_admin = ["SUPER_HOTEL","SUPER_ORDER","SUPER_TASK","SUPER_VOUCHER_MAKER","SUPER_SERVICE","SUPER_PRODUCT","SUPER_PORTAL","SUPER_PORTAL_USER","SUPER_PORTAL_ROLE"]

     
  

  onCheckAll(values) {
    let res = this.getCheckAllValues(values);
    if (res.all) {
      this.onChange(values, []);
    } else {
      if (res.indeterminate) {
        this.onChange(values, []);
      } else {
        this.onChange(values, values);
      }
    }
  }

  onChange(values, checkedValues) {
    let operations = this.state.operations;
    for (let v of values) {
      let index = operations.indexOf(v);
      if (index > -1) {
        operations.splice(index, 1);
      }
    }
    operations = operations.concat(checkedValues);
    this.setState({ operations });
  }

  getCheckAllValues(values) {
    console.log('value', values)
    let all = true;
    let indeterminate = false;
    let count = 0;
    for (let i = 0; i < values.length; i++) {
      let v = values[i];
      if (this.state.operations.indexOf(v) === -1) {
        all = false;
      } else {
        count++;
      }
    }
    if (!all && count > 0) {
      indeterminate = true;
    }
    let result = { all, indeterminate };
    return result;
  }

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div className="p16">
          <div className="ant-card ant-card-bordered margin-bootom-normal">
            {/*  ---------基础信息--------- */}
            <div className="ant-card-head">
              <div className="ant-card-head-wrapper">
                <div className="ant-card-head-title">基础信息</div>
              </div>
            </div>
            <div className="mt-20px">
              <Form
                ref="form"
                initialValues={{ visible: true }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="name"
                  label={<span style={{ paddingRight: 8 }}>角色名称</span>}
                  rules={[{ required: true, message: "请输入角色名称!" }]}
                >
                  <Input className="w-400px" placeholder="请输入角色名称" />
                </Form.Item>
                {this.state.type === 'SUPERVISOR'?null:<Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_portal);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_portal)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_portal).all
                      }
                    >
                      小店权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_portal,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="PORTAL_VIEW">站点查看</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PORTAL_UPDATE">站点更新</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PORTAL_REMOVE">站点删除</Checkbox>
                      </Col>
                      <Col span={6}>
                         
                      </Col>
                    </Row> 
                  </Checkbox.Group>
                </Form.Item>}

                {this.state.type === 'SUPERVISOR'?null:<Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_user);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_user)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_user).all
                      }
                    >
                      用户权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_user,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="PORTAL_USER_VIEW">查看小店用户</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PORTAL_USER_ADD">添加小店用户</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PORTAL_USER_REMOVE">移除小店用户</Checkbox>
                      </Col>
                      <Col span={6}>
                      <Checkbox value="PORTAL_USER_ROLE">设置小店用户角色</Checkbox>
                      </Col>
                    </Row> 
                  </Checkbox.Group>
                </Form.Item>}

                {this.state.type === 'SUPERVISOR'?null:<Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_role);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_role)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_role).all
                      }
                    >
                      角色权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_role,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="PORTAL_ROLE_VIEW">查看小店角色</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PORTAL_ROLE_ADD">添加小店角色</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PORTAL_ROLE_UPDATE">修改小店角色</Checkbox>
                      </Col>
                      <Col span={6}>
                      <Checkbox value="PORTAL_ROLE_REMOVE">删除小店角色</Checkbox>
                      </Col>
                    </Row> 
                  </Checkbox.Group>
                </Form.Item>}

                {this.state.type === 'SUPERVISOR'?null:<Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_product);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_product)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_product).all
                      }
                    >
                      产品权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_product,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="PRODUCT_VIEW">查看产品</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PRODUCT_UPDATE">创建或更新产品</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="PRODUCT_REMOVE">删除产品</Checkbox>
                      </Col>
                      <Col span={6}>
                       
                      </Col>
                    </Row> 
                  </Checkbox.Group>
                </Form.Item>}

                {this.state.type === 'SUPERVISOR'?null:<Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_order);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_order)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_order).all
                      }
                    >
                      订单权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_order,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="ORDER_VIEW">查看订单</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="ORDER_UPDATE">处理订单</Checkbox>
                      </Col>
                      <Col span={6}>
                         
                      </Col>
                      <Col span={6}>
                       
                      </Col>
                    </Row> 
                  </Checkbox.Group>
                </Form.Item>}
                

                {this.state.type === 'SUPERVISOR'?null:<Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_voucher);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_voucher)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_voucher).all
                      }
                    >
                      优惠券权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_voucher,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="VOUCHER_MAKER_VIEW">
                        查看优惠券
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="VOUCHER_MAKER_EMIT">
                        创建或发放优惠券
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>}

                {
                  this.state.type === 'SUPERVISOR' ? <Form.Item
                  label={
                    <Checkbox
                      onClick={() => {
                        this.onCheckAll(this.operations_admin);
                      }}
                      indeterminate={
                        this.getCheckAllValues(this.operations_admin)
                          .indeterminate
                      }
                      checked={
                        this.getCheckAllValues(this.operations_admin).all
                      }
                    >
                      超管权限
                    </Checkbox>
                  }
                >
                  <Checkbox.Group
                    value={this.state.operations}
                    onChange={(checkedValues) => {
                      this.onChange(
                        this.operations_admin,
                        checkedValues
                      );
                    }}
                    style={{ width: 600 }}
                  >
                    <Row style={{marginLeft:24}}>
                      <Col span={6}>
                        <Checkbox value="SUPER_HOTEL">
                        管理HOTEL
                        </Checkbox>
                      </Col>
                      
                      <Col span={6}>
                        <Checkbox value="SUPER_ORDER">
                        管理订单 
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="SUPER_TASK">
                        管理任务 
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                         
                      </Col>
                    </Row>
                    <Row style={{marginLeft:24}}>
                    <Col span={6}>
                        <Checkbox value="SUPER_VOUCHER_MAKER">
                        管理优惠券 
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="SUPER_SERVICE">
                        管理service
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="SUPER_PRODUCT">
                        管理所有产品 
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="SUPER_PORTAL">
                        管理所有站点 
                        </Checkbox>
                      </Col>
                    </Row>

                    <Row style={{marginLeft:24}}>
                    <Col span={6}>
                        <Checkbox value="SUPER_PORTAL_USER">
                        管理所有站点用户 
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="SUPER_PORTAL_ROLE">
                        管理所有站点角色
                        </Checkbox>
                      </Col>
                      <Col span={6}>
                        
                      </Col>
                      <Col span={6}>
                         
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>:null
                }
                
              </Form>
            </div>
            {/*  ---------保存--------- */}
            <div className="p24 border-top-1px">
              <Space>
                <Link to={`/role/list/${this.state.portalId}`}>
                  <Button icon={<ArrowLeftOutlined />}>取消</Button>
                </Link>
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    this.refs.form.submit();
                  }}
                >
                  保存
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
