import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Store } from "../../../reflux/index";
import { Button, Space, Table, Breadcrumb, Popconfirm, Divider } from "antd";
import { PortalActions, PortalStore } from "../../../reflux/portal";

export default class PortalRoleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      page_header: Store.collapseHeader,
      portalID: props.match.params.portalId,
      results: [],
      page: 1,
      pageSize: 10,
    };
    this.un_index = Store.listen(this.onStatusChange);
    this.un_admin = PortalStore.listen(this.onStatusChange.bind(this)); // 绑定，酒店 store
  }

  componentDidMount() {
    PortalActions.portalRoleList({
      portalID: this.state.portalID,
    });
    PortalActions.portal({ id: this.state.portalID });
  }

  componentWillUnmount() {
    this.un_admin(); // 解绑定，酒店
    this.un_index();
  }

  columns = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "可编辑",
      dataIndex: "editable",
      key: "editable",
      render: (text, it) => {
        return it.editable ? "Y" : "N";
      },
    },
    {
      title: "可设置为默认",
      dataIndex: "defaultable",
      key: "defaultable",
      render: (text, it) => {
        return it.defaultable ? "Y" : "N";
      },
    },
    {
      title: "系统角色",
      dataIndex: "isSystem",
      key: "isSystem",
      render: (text, it) => {
        return it.isSystem ? "Y" : "N";
      },
    },
    {
      title: "操作",
      key: "op",
      width: 180,
      render: (text, record) => {
        return (
          <Space>
            <Popconfirm
              title="Are you sure to delete this data?"
              onConfirm={() => {
                PortalActions.portalRoleRemove({
                  portalID: this.state.portalID,
                  id: record.id,
                });
              }}
              okText="Yes"
              cancelText="No"
            > 
            <Button type="link">删除</Button> 
            </Popconfirm>
            <Divider type="vertical" />
            <Link to={`/role/create/${this.state.portalID}/${record.id}`}>编辑</Link>
          </Space>
        );
      },
    },
  ];

  onStatusChange = (type, data, foreignId) => {
    switch (type) {
      case "portalRoleDefault":
        PortalActions.portalRoleList({
          portalID: this.state.portalID,
        });
        break;
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "portal":
        this.setState({ ...data });
        break;
      case "portalRoleRemove":
        PortalActions.portalRoleList({
          portalID: this.state.portalID,
        });
        break;
      case "portalRoleList":
        for (let it of data) {
          if (it.isDefault) {
            this.setState({ selectedRowKeys: [it.id] });
          }
        }
        this.setState({ results: data });
        break;
      default:
        break;
    }
  };

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>小店管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/portal/list">小店列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{this.state.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1>角色列表</h1>
          小店（{this.state.name}）下的角色列表
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            <Link to={`/role/create/${this.state.portalID}/new`}>
              <Button className="margin-bootom-normal">添加角色</Button>
            </Link>
          </div>
          <Table
            rowKey="id"
            rowSelection={{
              columnWidth: 90,
              columnTitle: "默认角色",
              selectedRowKeys: this.state.selectedRowKeys,
              type: "radio",
              onChange: (selectedRowKeys, selectedRows, info) => {
                this.setState({ selectedRowKeys });
                PortalActions.portalRoleDefault({
                  portalID: this.state.portalID,
                  id: selectedRowKeys[0],
                });
              },
            }}
            bordered
            columns={this.columns}
            dataSource={this.state.results}
          />
        </div>
      </div>
    );
  }
}
