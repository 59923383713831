import React, { Component } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Common from "../common/index";
import getConfig from "../config/index";
export default class Logo extends Component {
  constructor(props) {
    super(props); 
    this.state = { photos: [], dir:props.dir, fileList: [], OSSData: {} };
  }

  componentDidMount() {}
  componentWillUnmount() {}

  componentWillReceiveProps(props) {
    let update = false;
    if (props.photos.length !== this.state.photos.length) {
      update = true;
    }
    if (!update && props.photos.length > 0) {
      if (
        props.photos[0].id !== undefined &&
        props.photos[0].id !== this.state.photos[0].id
      ) {
        update = true;
      }
    }
    if (update) {
      let fileList = [];
      let photos = JSON.parse(JSON.stringify(props.photos));
      for (let photo of photos.reverse()) {
        fileList.push({
          id: photo.id,
          url: photo.path,
          uid: photo.id,
          status: "done"
        });
      }
      this.setState({ fileList, photos: props.photos });
    }
  }

  onStatusChange = (type, data) => {
    switch (type) {
      case "addPhoto":
      default:
        break;
    }
  };
  getExtraData = file => {
    let OSSData = this.state.OSSData; 
    let res = {
      key: file.url,
      OSSAccessKeyId: OSSData.accessid,
      policy: OSSData.policy, 
      Signature: OSSData.signature
    };
    console.log("getExtraData", res);
    return res;
  };
  beforeUpload = async file => {
    let res = await Common.upToken(file.name, this.state.dir); 
    this.state.OSSData = res.data; 
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = this.state.OSSData.dir + filename; 
    file.oss = this.state.OSSData.host;
    return file; 
  };

  /** 图片变更 */
  onChange = ({ file, fileList }) => {  
    let url = file.oss + '/' + file.url;  
    fileList = [{ thumbUrl: url, url: url }];

    setTimeout(() => {
      this.props.change(file.oss, url);
      this.setState({ fileList });
    }, 1500);
    // }
  };

  render() {
    const config = getConfig();

    const props = {
      name: "file",
      showUploadList: false,
      multiple: false,
      fileList: this.state.fileList,
      listType: "picture-card",
      action: config.oss,
      onChange: this.onChange,
      data: this.getExtraData,
      beforeUpload: this.beforeUpload
    };

    return (
      <Upload {...props}>
        {this.state.fileList.length === 0 ? (
          <PlusOutlined style={{ fontSize: 48 }} />
        ) : (
          <img
            alt=""
            style={{ width: "90%", height: "90%", objectFit: "cover" }}
            src={this.state.fileList[0].url}
          />
        )}
      </Upload>
    );
  }
}
