import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { Store } from "../../../reflux/index";
import {
  Button,
  Select,
  Space,
  Table,
  Breadcrumb,
  Popconfirm,
  message,
  Modal,
} from "antd";
import { PortalActions, PortalStore } from "../../../reflux/portal";
const { confirm } = Modal;
export default class PortalUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      page_header: Store.collapseHeader,
      portalID: props.match.params.portalId,
      results: [],
      page: 1,
      pageSize: 10,
    };
    this.un_index = Store.listen(this.onStatusChange);
    this.un_admin = PortalStore.listen(this.onStatusChange.bind(this)); // 绑定，酒店 store
  }

  async componentDidMount() {
    await PortalActions.portalRoleList({
      portalID: this.state.portalID,
    });

    PortalActions.portalUserList({
      keyWords: "",
      portalID: this.state.portalID,
      page: this.state.page,
      size: this.state.pageSize,
    });
    PortalActions.portal({ id: this.state.portalID });
  }

  componentWillUnmount() {
    this.un_admin(); // 解绑定，酒店
    this.un_index();
  }

  columns = [
    {
      title: "待加入",
      width: 100,
      dataIndex: "isVirtual",
      key: "isVirtual",
      render: (text, it) => {
        return it.isVirtual ? "Y" : "";
      },
    },
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
      render: (text, it) => {
        return it.user ? it.user.name : "";
      },
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile",
      render: (text, it) => {
        return it.user ? it.user.mobile : it.identity;
      },
    },
    {
      title: "角色",
      dataIndex: "role",
      key: "role",
      width: 180,
      render: (text, it) => {
        let value = it.role ? it.role.id : "";
        let isOwner = false;
        if(this.state.selectedRowKeys.length > 0){
          if(this.state.selectedRowKeys[0] === it.id){
            isOwner = true;
          }
        }
        return (isOwner?"拥有者":<Select
            onChange={(value) => {
              PortalActions.portalUserRoleChange({
                portalID: this.state.portalID,
                id: it.id,
                roleID: value,
              });
            }}
            defaultValue={value}
            style={{
              width: 120,
            }}
          >
            {this.state.roles.map((it, index) => {
              return (
                <Select.Option key={it.value} value={it.value}>
                  {it.label}
                </Select.Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: "操作",
      key: "op",
      width: 100,
      render: (text, record) => {
        return (
          <Space>
            <Popconfirm
              title="Are you sure to delete this data?"
              onConfirm={() => {
                PortalActions.portalUserRemove({
                  portalID: this.state.portalID,
                  id: record.id,
                });
              }}
              okText="Yes"
              cancelText="No"
            >
              <a>删除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  onStatusChange = (type, data, foreignId) => {
    switch (type) {
      case "collapseHeader":
        this.setState({ page_header: data });
        break;
      case "portal":
        this.setState({ ...data });
        break;
      case "portalUserRemove":
        PortalActions.portalUserList({
          keyWords: "",
          portalID: this.state.portalID,
          page: this.state.page,
          size: this.state.pageSize,
        });
        break;
      case "portalUserRoleChange":
        message.success("角色已变更");
        PortalActions.portalUserList({
          keyWords: "",
          portalID: this.state.portalID,
          page: this.state.page,
          size: this.state.pageSize,
        });
        break;
      case "portalUserList":
        for (let it of data.results) {
          if (it.isOwner) {
            this.setState({ selectedRowKeys: [it.id] });
          }
        }
        this.setState({ ...data });
        break;
      case "portalRoleList":
        let roles = [];
        for (let d of data) {
          roles.push({
            label: d.name,
            value: d.id,
          });
        }
        this.setState({ roles });
        break;
      default:
        break;
    }
  };

  getPageHeader = () => {
    if (this.state.page_header === "0") {
      return (
        <div className="info-header">
          <Breadcrumb>
            <Breadcrumb.Item>小店管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/portal/list">小店列表</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{this.state.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1>用户列表</h1>
          小店（{this.state.name}）下的用户列表
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        {this.getPageHeader()}
        <div style={{ padding: 16 }}>
          <div className="flux space-between">
            <div></div>
            <Link to={`/user/create/${this.state.portalID}/new`}>
              <Button className="margin-bootom-normal">添加用户</Button>
            </Link>
          </div>
          <Table
            rowKey="id"
            rowSelection={{
              columnWidth: 70,
              columnTitle: "归属于",
              selectedRowKeys: this.state.selectedRowKeys,
              type: "radio",
              onChange: (selectedRowKeys, selectedRows, info) => {
                let self = this;
                confirm({
                  title: "修改小店归属人",
                  icon: <ExclamationCircleOutlined />,
                  content: "是否确认，修改此小店的归属人，此操作一定要谨慎?",

                  onOk() {
                    self.setState({ selectedRowKeys });
                    PortalActions.portalUserUpdateOwner({
                      portalID: self.state.portalID,
                      id: selectedRowKeys[0],
                    });
                  },

                  onCancel() {
                    console.log("Cancel");
                  },
                });
              },
            }}
            bordered
            columns={this.columns}
            dataSource={this.state.results}
          />
        </div>
      </div>
    );
  }
}
